import React, { Fragment } from 'react';
import { Card, Statistic, Row, Col, Button } from "antd";

class MasterWalletInfoPanel extends React.Component {
  render() {
    const { data, loading, onSendClick, currentUser } = this.props;
    const defaultDestAddressLoad = [5,29,30,31,32,34,47,51,52,59,60,61,63,69,71,72,73,74,75,76,77,78,79,80,81,82,83,85];
    const loadDefaultAddress = defaultDestAddressLoad.includes(currentUser.wallet.wallet_group_id);
   
    return (
      <div>
        <Card loading={loading}>
          <Row gutter={24}>
            <Col xs={24} sm={24} lg={4} span={4}>
              <Statistic title="Master Wallet Id" value={data.wallet_id} groupSeparator='' valueStyle={{ fontSize: 16 }}/>
            </Col>
            <Col xs={24} sm={24} lg={5} span={5}>
              <Statistic title="Master Wallet Name" value={data.master_wallet_name != null ? data.master_wallet_name : ' '} valueStyle={{ fontSize: 16 }}/>
            </Col>
            <Col xs={24} sm={24} lg={5} span={5}>
              <Statistic title="Master Wallet Email" value={data.master_wallet_email != null ? data.master_wallet_email : ' '} valueStyle={{ fontSize: 16 }}/>
            </Col>
            <Col xs={24} sm={24} lg={5} span={5}>
              <Statistic title="Master Wallet Address" value={data.master_wallet_email != null ? data.wallet_address : ' '} valueStyle={{ fontSize: 16 }}/>
            </Col>
            <Col xs={24} sm={24} lg={5} span={5}></Col>
          </Row>
          <br />
          <Row gutter={24}>
            <Col xs={24} sm={12} lg={4} span={4}>
              <Statistic title="Master Wallet Balance" value={Number(data.balance)} precision={4} valueStyle={{ fontSize: 18 }}/>
            </Col>
            <Col xs={24} sm={12} lg={5} span={5}>
              <Statistic title="Master Wallet Pending Out" value={Number(data.pending_out)} precision={4} valueStyle={{ fontSize: 18 }}/>
            </Col>
            <Col xs={24} sm={12} lg={5} span={5}>
              <Statistic title="Sub Wallets Total Balance" value={Number(data.total_balance)} precision={4} valueStyle={{ fontSize: 18 }}/>
            </Col>
            <Col xs={24} sm={12} lg={5} span={5}>
                <Statistic title="Sub Wallets Total Pending Out" value={Number(data.total_pending_out)} precision={4} valueStyle={{ fontSize: 18 }}/>
            </Col>
              <Fragment>
                <Col xs={24} sm={24} lg={5} span={5} style={{ textAlign: "right", paddingTop: "5px" }}>
                {data.allow_withdraw && ( 
                    <span>
                      <Button key="send" type="primary" onClick={() => onSendClick(data.wallet_id, data.wallet_address, false, loadDefaultAddress)}>
                        Send
                      </Button>
                    </span>
                )}
                </Col>
              </Fragment>
          </Row>
        </Card>
      </div>
    );
  }
}

export default MasterWalletInfoPanel;
