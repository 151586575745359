import App, { Container } from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import lessToJs from 'less-vars-to-js';
import camelcaseKeys from 'camelcase-keys';
import jsHttpCookie from 'cookie';
import jsCookie from 'js-cookie';
import Router from 'next/router';
import { message } from 'antd';

import withReduxStore from '../store';
import userProfile from 'modules/userProfile';

import 'bulma/bulma.sass'; // bulma
import '../styles/app.less'; // others

const themeLess = require('!!raw-loader!../styles/antd-custom.less');

let theme = lessToJs(themeLess, { resolveVariables: true, stripPrefix: true });
theme = camelcaseKeys(theme);

const {
    actions: { setAccessToken, fetchCurrentUser }
} = userProfile;

class MyApp extends App {
    static async getInitialProps({ Component, ctx }) {
        let pageProps = {};

        const { req, reduxStore } = ctx;
        let accessToken = null;
        if (req && req.headers) {
            const cookies = req.headers.cookie;

            // get cookie server side
            if (typeof cookies === 'string') {
                const cookiesJSON = jsHttpCookie.parse(cookies);
                accessToken = cookiesJSON.accessToken || null;
            }
        } else {
            // get cookie client side
            const cookiesJSON = jsCookie.getJSON();
            accessToken = cookiesJSON.accessToken || null;
        }

        if (accessToken) {
            await reduxStore.dispatch(setAccessToken(accessToken));
            await reduxStore.dispatch(fetchCurrentUser());
        }

        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx);
        }

        pageProps = { ...pageProps, accessToken };

        return { pageProps };
    }

    componentDidMount() {
        const { reduxStore } = this.props;
        const accessToken = reduxStore.getState().userProfile?.accessToken;
        if (accessToken) {
            // extends expires time if user is currently using
            jsCookie.set('accessToken', accessToken, { expires: 1 });
        }

        Router.events.on('routeChangeComplete', () => {
            window.scrollTo(0, 0);
        });
    }

    render() {
        const { Component, pageProps, reduxStore } = this.props;

        return (
            <Container>
                <Provider store={reduxStore}>
                    <ThemeProvider theme={theme}>
                        <Component {...pageProps} />
                    </ThemeProvider>
                </Provider>
            </Container>
        );
    }
}

export default withReduxStore(MyApp);
