import React, { Component } from 'react';
import styled from 'styled-components';
import { Table, Typography, Tag, Tooltip, Button, Icon } from 'antd';
import moment from 'moment';

const { Text } = Typography;

const StyledTable = styled(Table)`
    .ant-table-placeholder{
        border-bottom: 0px;
    }
    background-color: #fff;
`;

const StyledTag = styled(Tag)`
    text-align: center;
    width: 108px;
    margin-top: 2px;
`;

class TransactionSummary extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Date",
        dataIndex: 'completed_at',
        key: 'completed_at',
        render: (text, data) => (
          <span>
            <Text>{moment.utc(data.completed_at).local().format('YYYY-MM-DD')}</Text>
            <br />
            <Text type="secondary">{moment.utc(data.completed_at).local().format('hh:mm a')}</Text>
          </span>
        )
      },
      {
        title: 'Transaction Id',
        dataIndex: 'transaction_id',
        key: 'transaction_id',
      },
      {
        title: "Transaction Type",
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: "Currency",
        dataIndex: 'currency_code',
        key: 'currency_code',
      },
      {
        title: "Amount",
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
      },
      {
        title: "Balance",
        key: 'balance',
        align: 'right',
        render: (text, data) => (
            <span>
              <Text strong>{data.new_balance}</Text>
              <br />
              <Text type="secondary">{data.previous_balance}</Text>
            </span>
          )
      }
    ];

    if (process.env.FEE_APPLICABLE == "true") {
      this.columns.splice(5, 0, {
        title: "Fees",
        dataIndex: 'fee',
        key: 'fee',
        align: 'right',
      });
      
      this.columns.splice(6, 0, {
        title: "Actual Amount",
        dataIndex: 'total_amount',
        key: 'total_amount',
        align: 'right',
      });
    }
  }

    getTxLink = (txHash, currencyCode) => {
        var txLink = this.generateTxLink(txHash, currencyCode);

        return (
            <span>
                <Tooltip title={txLink.title}>
                    <Button 
                        shape="circle"
                        style={{ backgroundColor: '#0e5bdedb', color: '#ffffff' }}
                        disabled={!txHash}
                        href={txLink.link}
                        target='_blank'
                    >
                      <Icon type="link" />
                    </Button>
                  </Tooltip>
              </span>
        ); 
    };

    expandedRowRender = record => {
        let expanderColumns = [
          {
            title: "Transaction Hash",
            dataIndex: "tx_hash",
            key: "tx_hash",
            render: (text, data) => (
                <span>
                  <a href={data.tx_link} target="_blank">
                    {data.tx_hash}
                  </a>
                </span>
            )
          },
          {
            title: "Remark",
            dataIndex: 'remark',
            key: 'remark',
          },
        ];

        if (record.type == 'Transfer' || record.type == 'Withdraw') {
            expanderColumns.splice(0, 0, {
                title: "Destination Address",
                dataIndex: 'destination_address',
                key: 'destination_address'
            });
        }
    
        const data = [];
        var txLink = this.generateTxLink(record.tx_hash, record.currency_code);

        data.push({
            destination_address: record.destination_address,
            tx_hash: record.tx_hash,
            tx_link: txLink.link,
            remark: record.remark
        });
    
        return (
          <Table
            rowKey="id"
            columns={expanderColumns}
            dataSource={data}
            pagination={false}
          />
        );
    };

    generateTxLink = (txHash, currencyCode) => {
        let link = '';
        let title = '';
        if (currencyCode === 'USDT') {
            if (process.env.ETHEREUM_ENV !== 'ropsten') {
                link = 'https://etherscan.io/tx/' + txHash;
            } else {
                link = 'https://ropsten.etherscan.io/tx/' + txHash;
            }
            title = "View in Etherscan"; 
        } else if (currencyCode === 'USDT-TRC20') {
            link = 'https://tronscan.io/#/transaction/' + txHash;
            title = "View in Tronscan";
        } else if (currencyCode === 'BTC') {
            link = 'https://www.blockchain.com/btc/tx/' + txHash;
            title = "View in Blockchain";
        }
        return { link, title };
    }

  render() {
    return (
      <StyledTable
        bordered={true}
        columns={this.columns}
        dataSource={this.props.data}
        loading={this.props.loading}
        rowKey="id"
        pagination={{
          onChange: page => {
            this.props.handleFetchData(page);
          },
          current: this.props.pagination.current_page,
          total: this.props.pagination.total,
          pageSize: this.props.pagination.per_page,
        }}
        expandedRowRender={record => this.expandedRowRender(record)}
        rowClassName="show"
      />
    );
  }
}

export default TransactionSummary;
