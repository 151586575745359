import React, { Component } from "react";
import { connect } from "react-redux";
import numeral from 'numeral';
import {Row, Col, Divider, Button, Modal, Input} from "antd";
import { ethers } from 'ethers';

import {withdrawCrypto, transferCrypto, getFee, clearFeeData, verifyOtp} from "../ducks/list";
import { SendForm } from "../components";
import { formatCrypto } from '../../core/utils';

class SendFormContainer extends Component {
  constructor(props) {
    super(props);

    this.form = [];
    this.etherscanProvider = new ethers.providers.EtherscanProvider('mainnet', process.env.ETHERSCANAPIKEY);

    this.state = {
      gasFee: null,
      showModalVerifyOtp: false,
      otpValue: '',
      isVerifyOtp: !this.props.currentUser.is_enable_google2fa,
    };
  }

  async componentDidMount() {  
    if (this.props.selectedTabCurrency === 'USDT') {
      this.setGasFeeEstimation();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isFormVisible && this.props.isFormVisible !== prevProps.isFormVisible && this.props.selectedTabCurrency === 'USDT') {
      this.setGasFeeEstimation();
    }
  }

  componentWillUnmount() {
      this.setState({ gasFee: null });
  }

  handleSubmit = async () => {
    const {withdrawCrypto, transferCrypto, walletId, handleFetchData, selectedTabCurrency, isTransfer} = this.props;
    const {gasFee, isVerifyOtp} = this.state;
      this.form.props.form.validateFields((err, values) => {
        if (!err) {
          const data = {
            amount: values.amount,
            wallet_id: walletId.toString(),
            dest_crypto_address: values.crypto_address,
            currency_code: selectedTabCurrency,
            remark: values.remark,
            transaction_id: ''
          };

          if (isTransfer) {
            data.type = "Transfer"
            data.gas_price = gasFee == null ? null : gasFee.gasPrice;

            transferCrypto({
              ...data,
              msgSuccess: "Transfer submitted successfully"
            }).then(response => {
              if (response && response.status === 200) {
                this.onClose();
                handleFetchData();
              }
            });
          } else {
            if (!isVerifyOtp){
              this.openModalVerifyOtp()
            }else{
              withdrawCrypto({
                ...data,
                msgSuccess: "Send submitted successfully"
              }).then(response => {
                if (response && response.status === 200) {
                  this.onClose();
                  handleFetchData();
                }
              });
            }
          }
        }
      });
  };

  onClose = () => {
    this.form.props.form.resetFields();
    this.props.clearFeeData();
    this.props.onClose();
    this.setState({ gasFee: null });
  };

  onGetFee = () => {
    const { form: { getFieldValue, getFieldError }} = this.form.props;
    const fieldError = getFieldError('amount');
    const amountVal = numeral(getFieldValue('amount')).value();

    if (process.env.FEE_APPLICABLE == "true" && !fieldError && amountVal) {
      const { getFee, walletId, selectedTabCurrency } = this.props;
      const data = {
        "wallet_id": walletId,
        "amount": getFieldValue('amount').toString(),
        "currency_code": selectedTabCurrency,
      }
      getFee({ ...data });
    } else {
      this.props.clearFeeData();
    }
  };

  setGasFeeEstimation = async () => {
    try {
      let etherscanGasResponse = await fetch(`${process.env.ETHERSCANAPIURL}?module=gastracker&action=gasoracle&apikey=${process.env.ETHERSCANAPIKEY}`).catch((err) => { console.error('Etherscan API error => ', err); });
      if (etherscanGasResponse) {
        const etherscanGasJson = await etherscanGasResponse.json().catch((err) => { console.error('Etherscan response parse error => ', err); });
        if (etherscanGasJson && etherscanGasJson.result) {
          let gasUsed = 84221; // avg gas used
          let estimatedGasPrice = parseFloat(etherscanGasJson.result.ProposeGasPrice).toString();

          let ethPrice = await this.etherscanProvider.getEtherPrice();
          let gweiFees = gasUsed * estimatedGasPrice;
          let ethFees = gweiFees * 0.000000001;
          let fee = ethFees * ethPrice;

          this.setState({
            gasFee: { fee: Number(fee).toFixed(2), gasPrice: estimatedGasPrice }
          });

          return;
        }
      }
    } catch(err) { 
      console.log('Fee estimation error => ', err);
    }

    this.setState({
      gasFee: null
    });
  }

  hideModalVerifyOtp = () => {
    this.setState({
      showModalVerifyOtp: false,
      otpValue: ''
    });
  };

  openModalVerifyOtp = () => {
    this.setState({
      showModalVerifyOtp: true,
      otpValue: ''
    });
  };

  submitVerifyOtp = () => {
    this.props.verifyOtp({otp: this.state.otpValue,verify_route_name: 'transactions.admin-withdraw',msgSuccess: "Verified 2FA OTP successfully!" }).then(async res => {
      if (res.status === 200) {
        await this.hideModalVerifyOtp()
        await this.setState({isVerifyOtp: true})
        await this.handleSubmit()
      }
    });
  }

  onChangeOtp = (value) => {
    this.setState({otpValue: value})
  }

  render() {
    const { submitting, balance, balanceLoading, walletAddress, feeLoading, feeData, selectedTabCurrency, showAdvanceFee, masterWalletAddress, isTransfer, destCryptoAddressLoading, destCryptoAddress, loadDefaultAddress, currentUser } = this.props;
    const { gasFee } = this.state;
    
    const bal = numeral(balance).value();
    const disableSubmitBtn = false;
    let headerTitle = isTransfer ? 'Transfer' : 'Send';

    return (
      <div>
        <h5 className="subtitle">{`${headerTitle} (${selectedTabCurrency})`}</h5>
        <Divider />
        <SendForm
          wrappedComponentRef={form => {
            this.form = form;
          }}
          balance={formatCrypto(balance)}
          balanceLoading={balanceLoading}
          walletAddress={walletAddress}
          onGetFee={this.onGetFee}
          feeData={feeData}
          feeLoading={feeLoading}
          showAdvanceFee={showAdvanceFee}
          currencyCode={selectedTabCurrency}
          masterWalletAddress={masterWalletAddress}
          isTransfer={isTransfer}
          gasFee={gasFee} 
          destCryptoAddressLoading={destCryptoAddressLoading}
          destCryptoAddress={destCryptoAddress}
          loadDefaultAddress={loadDefaultAddress}
          currentUser={currentUser}
        />
        <Modal
            title="Verify OTP"
            visible={this.state.showModalVerifyOtp}
            onOk={this.submitVerifyOtp}
            onCancel={this.hideModalVerifyOtp}
            okText="Verify"
            cancelText="Cancel"
        >
          <Row>
            <Col xs={6} md={6}>OTP</Col>
            <Col xs={18} md={18}><Input value={this.state.otpValue} placeholder="Enter OTP" onChange={(e) => {this.onChangeOtp(e.target.value)}} /></Col>
          </Row>
        </Modal>
        <Divider />
        <Row type="flex" align="middle" justify="center">
          <Col xs={24} md={24}>
            <div className="is-clearfix">
              <Button
                type="primary"
                onClick={this.handleSubmit}
                className="is-pulled-right"
                loading={submitting}
                disabled={disableSubmitBtn}
              >
                Submit
              </Button>
              <Button
                type="default"
                onClick={this.onClose}
                disabled={submitting}
                className="is-pulled-right margin-right-5"
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  wallet: {
    list: { submitting, feeData, feeLoading }
  }
}) => ({
  submitting, feeData, feeLoading
});

export default connect(
  mapStateToProps,
  { withdrawCrypto, transferCrypto, getFee, clearFeeData, verifyOtp }
)(SendFormContainer);
