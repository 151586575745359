import { combineReducers } from 'redux';

import reducerList, * as actionsList from './list';

const reducer = combineReducers({
  list: reducerList,
});

export const actions = {
  list: actionsList,
};

export default reducer;
