import React, { Fragment } from "react";
import { Form, Input, InputNumber, Spin, Row, Col } from "antd";
import numeral from 'numeral';

class SendForm extends React.Component {

  validateCryptoInput = (rule, value, callback) => {
    const { form, isTransfer, currentUser } = this.props;

    if (!isTransfer) {
        const currency = form.getFieldValue('currencyCode');

        var regexp = /^\d+(\.\d{1,4})?$/;
        var decimalPlaces = 4;
        var minimumAmount = currentUser.wallet.wallet_group_id === 47 ? 1 : 10;
    
        if (currency === 'BTC') {
            regexp = /^\d+(\.\d{1,8})?$/;
            decimalPlaces = 8;
            minimumAmount = 0.0005;
        } 
    
        if (value && currency) {
            if (!regexp.test(value)) {
                callback(`Please enter a valid number with ${decimalPlaces} decimal places`);
            } else {
                if (numeral(value).value() < minimumAmount) {
                  callback(`Minimum amount you can withdraw is ${minimumAmount}!`);
                }
            }
            callback();
        }
    } else {
        callback();
    }
  };

  displayBalance = () => {
    const { balanceLoading, balance } = this.props;
    return balanceLoading ? <Spin size="small" /> : <span style={{ fontSize: '14px', fontWeight: 'bold' }}>Available balance : {balance}</span>
  }

  getGasPrice = () => {
    const { gasFee } = this.props;
    return gasFee ? <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{gasFee.gasPrice} GWEI</span> : '';
  }

  render() {
    const { onGetFee, feeData, feeLoading, showAdvanceFee, walletAddress, currencyCode, masterWalletAddress, isTransfer, balance, gasFee, form: { getFieldDecorator }, destCryptoAddressLoading, destCryptoAddress, loadDefaultAddress } = this.props;

    return (
      <div>
        <Form
          layout="vertical"
          ref={this.wrappedComponentRef}
          labelCol={{ xs: { span: 24 } }}
          wrapperCol={{ xs: { span: 24 } }}
          labelAlign="left"
        >
          <Form.Item label="Wallet Address">
            <Input value={walletAddress} disabled />
          </Form.Item>
          <Form.Item label="Destination Crypto Address">
            {getFieldDecorator("crypto_address", {
              rules: [
                {
                  required: true,
                  message: "Please input crypto address!"
                }
              ],
              initialValue: isTransfer ? masterWalletAddress : loadDefaultAddress && !destCryptoAddressLoading ? destCryptoAddress : "",
            })(<Input value={loadDefaultAddress && !destCryptoAddressLoading ? destCryptoAddress : ''} disabled={isTransfer || loadDefaultAddress} placeholder={loadDefaultAddress && destCryptoAddressLoading ? "Loading..." : '' } />)}
          </Form.Item>
          <Form.Item label="Currency" style={{ display: 'none'}}>
          {getFieldDecorator("currencyCode", {
              initialValue: currencyCode,
            })(<Input value={currencyCode} type="hidden"/>)}
          </Form.Item>
          <Form.Item label="Amount" extra={this.displayBalance()}>
            {getFieldDecorator("amount", {
              rules: [
                {
                  required: true,
                  message: "Please input amount!"
                },
                {
                  validator: this.validateCryptoInput,
                }
              ],
              initialValue: isTransfer ? numeral(balance).value() : "",
            })(<InputNumber min={0} onBlur={onGetFee} style={{ width: 'max-content'}} disabled={isTransfer} />)}
          </Form.Item>
          {process.env.FEE_APPLICABLE == "true" && !isTransfer &&
              <Fragment>
                <Row>
                    <Col span={14}>
                        <Form.Item label={`Actual Amount`}>
                          <InputNumber disabled value={ feeData?.total_amount} /> {feeLoading && <Spin size="small" />}
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item label={showAdvanceFee ? `Gateway Fees` : `Fees`}>
                          <InputNumber disabled value={showAdvanceFee ? feeData?.gateway_fee : feeData?.fee} /> {feeLoading && <Spin size="small" />}
                        </Form.Item>
                    </Col>
                </Row>
                {showAdvanceFee &&
                  <Row>
                    <Col span={14}>
                        <Form.Item label={`Amount Customer Receive`}>
                          <InputNumber disabled value={ feeData?.total_amount_sent} /> {feeLoading && <Spin size="small" />}
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item label={`Gas Fees`}>
                          <InputNumber disabled value={feeData?.gas_fee} /> {feeLoading && <Spin size="small" />}
                        </Form.Item>
                    </Col>
                  </Row>
                }
              </Fragment>
          }
          {isTransfer && currencyCode === 'USDT' && 
            <Form.Item label={`Gas Fees`} extra={this.getGasPrice()}>
              <Input disabled value={gasFee?.fee} addonAfter={"USD"} style={{ width: '125px'}}/>
            </Form.Item>
          }
          <Form.Item label={`Remark`}>
            {getFieldDecorator('remark', {})(
              <Input />
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

SendForm = Form.create({ name: "send_form" })(
    SendForm
);

export default SendForm;
