import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { Spin, PageHeader, Modal } from 'antd';

import { ExternalTransactionList } from 'modules/external/components';
import { EmptyTransactionList } from 'modules/core/components';
import { fetchExternalTransactions } from '../ducks/list';
import TransactionUpdateContainer from './TransactionUpdateContainer';

class ExternalTransactionListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      modalVisible: false,
      transactionData: {}
    };
  }

  componentDidMount() {
    const { page } = this.state;
    const data = { page };
    this.props.fetchExternalTransactions(data);
  }

  handleFetchData = page => {
    this.setState({
      page: page
    });
    const data = { page };
    this.props.fetchExternalTransactions(data);
  };

  onMoreClick = (data) => {
    this.setState({ modalVisible: true, transactionData: data });
  };

  onCancel = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    const {
      list: { data, pagination, loading }
    } = this.props;

    const { modalVisible, transactionData } = this.state;

    if (loading) {
      return <Spin size="large" />;
    }

    return (

      <div>
        <PageHeader title="External - Top Ups" />
        {isEmpty(data) ? <EmptyTransactionList /> :
          <ExternalTransactionList
            data={data}
            pagination={pagination}
            handleFetchData={this.handleFetchData}
            onMoreClick={this.onMoreClick}
          />}
        <Modal
          centered
          visible={modalVisible}
          footer={null}
          maskClosable={true}
          onCancel={this.onCancel}
          destroyOnClose={true} >
            <TransactionUpdateContainer 
              onCancel={this.onCancel}
              handleFetchData={this.handleFetchData}
              transactionData={transactionData} />
        </Modal>
      </div>

    )
  }
}

const mapStateToProps = ({ external: { list } }) => ({ list });

export default connect(mapStateToProps, { fetchExternalTransactions })(ExternalTransactionListContainer);
