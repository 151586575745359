import dotProp, { set } from 'dot-prop-immutable-chain';
import { handleActions } from 'redux-actions';
import axios from 'axios';
import { asyncActions } from 'modules/core/utils';
import { API_URL } from '../constants';

const INITIAL_STATE = {
    data: [],
    pagination: null,
    loading: false,
    error: null
};

// ------------------------------------------- ACTIONS -------------------------------------------
export const fetchTopUps = asyncActions('TRANSACTIONS/FETCH_TOP_UPS', (data) => axios.get(`${API_URL}`, { params: data }))
// ------------------------------------------- REDUCER -------------------------------------------
export default handleActions(
    {
        [fetchTopUps.START]: (state, { payload }) => set(state, 'loading', true),

        [fetchTopUps.SUCCESS]: (state, { payload }) => {      
            const {
                data,
                meta: { pagination }
            } = payload.data;

            return dotProp(state)
                .set('data', data)
                .set('pagination', pagination)
                .set('loading', false)
                .set('error', null)
                .value();
        }
    },
    INITIAL_STATE
);
