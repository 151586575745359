import dotProp, { set } from 'dot-prop-immutable-chain';
import { handleActions } from 'redux-actions';
import axios from 'axios';
import { asyncActions } from 'modules/core/utils';
import { API_URL } from '../constants';

const INITIAL_STATE = {
  data: [],
  pagination: null,
  loading: false,
  error: null
};

// ------------------------------------------- ACTIONS -------------------------------------------
export const fetchLimitOrders = asyncActions('TRANSACTIONS/FETCH_LIMIT_ORDERS', (data) => {
  return axios.get(`${API_URL}/list`, { params: data });
})

export const cancelLimitOrder = asyncActions('TRANSACTIONS/CANCEL_LIMIT_ORDER', ({ id }) => 
  axios.put(`${API_URL}/${id}`, { is_admin: true }));

// ------------------------------------------- REDUCER -------------------------------------------
export default handleActions(
  {
    [fetchLimitOrders.START]: (state, { payload }) => set(state, 'loading', true),

    [fetchLimitOrders.SUCCESS]: (state, { payload }) => {
      const {
        data,
        meta: { pagination }
      } = payload.data;

      return dotProp(state)
        .set('data', data)
        .set('pagination', pagination)
        .set('loading', false)
        .set('error', null)
        .value();
    },

    [cancelLimitOrder.START]: state => set(state, 'loading', true),

    [cancelLimitOrder.SUCCESS]: state =>
      dotProp(state)
        .set('loading', false)
        .set('error', null)
        .value(),
  },
  INITIAL_STATE
);
