import React from 'react';
import { connect } from 'react-redux';
import Router from 'next/router';
import { Menu, Dropdown, Button, Icon, Avatar } from 'antd';
import { DEFAULT_AVATAR } from '../constants';
import { logout } from '../ducks';

const ProfileMenuContainer = ({ currentUser, logout }) => {
    if (!currentUser) return null;

    const handleMenuClick = e => {
        switch (e.key) {
            case 'settings':
                // Router.push('/profile/settings');
                break;

            case 'dashboard':
                break;

            case 'logout':
                logout();
                Router.push('/login');
                break;

            default:
                break;
        }
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="logout">
                <Icon type="logout" /> Logout
            </Menu.Item>
        </Menu>
    );

    const { profile_image } = currentUser;

    return (
        <Dropdown overlay={menu}>
            <Button style={{ border: 'none' }}>
                <Avatar
                    size="small"
                    style={{ marginRight: '5px' }}
                    src={profile_image ? profile_image.url : DEFAULT_AVATAR}
                />

                {currentUser.name || 'Wholesale'}

                <Icon type="down" />
            </Button>
        </Dropdown>
    );
};

const mapStateToProps = ({ userProfile: { currentUser } }) => ({ currentUser });

export default connect(
    mapStateToProps,
    { logout }
)(ProfileMenuContainer);
