import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

const StyledTopNavContent = styled.div`
    display: inline-block;
    margin-left: 20px;

    @media only screen and (max-width: ${props => props.theme.screenSmMax}) {
        display: none;
    }
`;

const TopNavContentContainer = ({ currentUser }) => {
    return (
        <StyledTopNavContent>
            Test User 1 (Wallet ID: 98798716)
        </StyledTopNavContent>
    );
};

const mapStateToProps = ({ userProfile: { currentUser } }) => ({ currentUser });

export default connect(mapStateToProps)(TopNavContentContainer);
