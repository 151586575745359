import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Table, Typography, Tag, Tooltip, Button, Icon, Row, Col } from 'antd';

import { getStatusTagColor } from '../../core/utils';

const { Text } = Typography;

const StyledTable = styled(Table)`
    .ant-table-placeholder{
        border-bottom: 0px;
    }
    padding: 5px 5px 5px 5px;
`;

const StyledTag = styled(Tag)`
    text-align: center;
    width: 108px;
    margin-top: 2px;
`;

class ExternalTransactionList extends Component {
  constructor(props) {
    super(props);

    this.externalData = this.props.data;

    this.columns = [
      {
        title: "Date",
        dataIndex: 'date',
        key: 'date',
        render: (text, data) => (
          <span>
            <Text>{moment.utc(data.date).local().format('YYYY-MM-DD')}</Text>
            <br />
            <Text type="secondary">{moment.utc(data.date).local().format('hh:mm a')}</Text>
          </span>
        )
      },
      {
        title: 'Transaction No',
        dataIndex: 'transaction_id',
        key: 'transaction_id',
      },
      {
        title: "Wallet/Alias",
        dataIndex: 'alias',
        key: 'alias',
      },
      {
        title: "Amount",
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
      },
      {
        title: "Requested Amount",
        dataIndex: 'crypto_amount',
        key: 'crypto_amount',
        align: 'right',
      },
      {
        title: "Received Amount",
        dataIndex: 'recieved_crypto_amount',
        key: 'recieved_crypto_amount',
        align: 'right',
      },
      {
        title: "Customer Wallet Address",
        dataIndex: 'customer_wallet_address',
        key: 'customer_wallet_address',
        render: (text, data) => (
          <span><a href={data.customer_wallet_url} target='_blank'>{data.customer_wallet_address}</a></span>
        ),
      },
      {
        title: "Status",
        dataIndex: 'status',
        key: 'status',
        width: 120,
        render: status => (
          <StyledTag color={getStatusTagColor(status)}>
            {status.toUpperCase()}
          </StyledTag>
        )
      },
      {
        title: 'Action',
        key: 'action',
        width: 85,
        align: 'center',
        fixed: 'right',
        render: (text, data) => data.status === 'Pending' || data.status === 'Mismatch' ?
          <span>
              <Tooltip title="More">
                <Button
                  shape="circle"
                  onClick={() => this.props.onMoreClick(data)}
                >
                  <Icon type="more" />
                </Button>
              </Tooltip>
          </span>
          : '',
      },
    ];
  }

  expandedRowRender = (record) => {
    const columns = [
      { 
        title: 'Ext. Reference No', 
        dataIndex: 'ext_ref_id', 
        key: 'ext_ref_id' 
      },
      {
        title: 'Customer Name',
        dataIndex: 'full_name',
        key: 'full_name'
      },
      { 
        title: 'Customer Email', 
        dataIndex: 'customer_email', 
        key: 'customer_email' 
      },
      { 
        title: 'Receiving Wallet Address', 
        dataIndex: 'wallet_address', 
        key: 'wallet_address', 
        render: (text, data) => (
          <span><a href={data.wallet_url} target='_blank'>{data.wallet_address}</a></span>
        ), 
      },
    ];

    const data = [];
    data.push({
      ext_ref_id: record.ext_ref_id,
      full_name: record.full_name,
      customer_email: record.customer_email,
      wallet_address: record.wallet_address,
      wallet_url: record.wallet_url,
    });

    return <Table rowKey="id" columns={columns} dataSource={data} pagination={false} />;
  }

  render() {
    return (
      <Row>
        <Col xs={0} md={24}>
          <StyledTable
            bordered={true}
            columns={this.columns}
            dataSource={this.externalData}
            rowKey="transaction_id"
            pagination={{
              onChange: page => {
                this.props.handleFetchData(page);
              },
              current: this.props.pagination.current_page,
              total: this.props.pagination.total,
              pageSize: this.props.pagination.per_page,
            }}
            expandedRowRender={record => this.expandedRowRender(record)}
            rowClassName="show"
          />
        </Col> 
      </Row>
    );
  }
}

export default ExternalTransactionList;
