import React, { Component } from 'react';
import styled from "styled-components";
import moment from 'moment';

import { Card, Row, Col, Form, DatePicker, Button, Input, Select } from 'antd';

const { Option } = Select;

class AdvancedFilter extends Component {
  constructor(props) {
    super(props);
  }

  onValidateDate = (rule, value, callback) => {
    const { form } = this.props;
    const fromdate = form.getFieldValue('fromDate');
    let todate = form.getFieldValue('toDate');
    if (todate == null) {
      todate = '';
    }
    
    if (fromdate && moment(fromdate).format('L') !== moment(todate).format('L')) {
        if (rule.field === 'fromDate') {
            if (moment(value).isAfter(moment(todate))) {
              callback('Invalid date selected');
            }
        } else if (moment(value).isBefore(fromdate)) {
              callback('Invalid date selected');
        }
    } 
    
    callback();
  };
  
  render() {
    const StyledDatePicker = styled(DatePicker)`
      width: 100%;
    `;

    const { getFieldDecorator } = this.props.form;
    const { statusList, onFilterClick, onExportClick, loading, exportable, onClear, type, userWalletGroupId } = this.props;

    return (
      <Row>
        <Col>
          <Card>
            <Form layout="horizontal" labelCol={{xs: {span:4}, md: {span:8}, lg: {span:8}}} wrapperCol={{xs: {span:16}, md: {span:16}, lg: {span:16}}}> 
                <Row gutter={[8,8]}>
                    <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                        <Form.Item label="From Date" style={{ display: 'flex' }}>
                            {getFieldDecorator('fromDate', {
                                rules: [
                                    { validator: this.onValidateDate }
                                ]})(
                                <StyledDatePicker placeholder="Select from date" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                        <Form.Item label="To Date" style={{ display: 'flex' }}>
                            {getFieldDecorator('toDate', {
                                rules: [
                                    { validator: this.onValidateDate }
                                ]})(
                                <StyledDatePicker placeholder="Select to date" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                        <Form.Item label="Hash" style={{ display: 'flex' }}>
                            {getFieldDecorator('hash', { })(
                                <Input placeholder="Enter hash" allowClear />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                        <Form.Item label="Wallet Address" style={{ display: 'flex' }}>
                            {getFieldDecorator('walletAddress', { })(
                                <Input placeholder="Enter wallet address" allowClear  />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                        <Form.Item label="Currency" style={{ display: 'flex' }}>
                            {getFieldDecorator('currency', {  })(
                                <Select placeholder="Select currency" allowClear>
                                    {userWalletGroupId && userWalletGroupId === 11 && <Option key="BTC">BTC</Option>}
                                    <Option key="USDT">USDT</Option>
                                    <Option key="USDT-TRC20">USDT-TRC20</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                        <Form.Item label="Status" style={{ display: 'flex' }}>
                            {getFieldDecorator('status', {  })(
                                <Select placeholder="Select status" allowClear>{statusList.map(item => ( <Option key={item}>{item}</Option> ))}</Select>
                            )}
                        </Form.Item>
                    </Col>
                    {type === 'Withdraw' && (
                        <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                            <Form.Item label="Reference Id" style={{ display: 'flex' }}>
                                {getFieldDecorator('refId', { })(
                                    <Input placeholder="Enter reference id" allowClear  />
                                )}
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col span={24} style={{ textAlign: 'right' }} >
                        <Button type="primary" onClick={onFilterClick} disabled={loading}>Filter</Button>
                        {exportable && (
                            <Button style={{ marginLeft: 8 }} type="primary" onClick={onExportClick} disabled={loading}>Export</Button>
                        )}
                        <Button style={{ marginLeft: 8 }} onClick={onClear}>Clear</Button>
                    </Col>
                </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

AdvancedFilter = Form.create({ name: "advanced_filter" })(AdvancedFilter);

export default AdvancedFilter;