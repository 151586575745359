import dotProp, { set } from 'dot-prop-immutable-chain';
import { handleActions, createAction } from 'redux-actions';
import axios from 'axios';
import { asyncActions } from 'modules/core/utils';
import { API_URL_UPDATE } from '../constants';

const INITIAL_STATE = {
    approveData: null,

};
// ------------------------------------------- ACTIONS -------------------------------------------

export const approveTopUps = asyncActions('TRANSACTION/APPROVE_TOP_UPS', ({ id , isapproved}) =>{
  return axios.put(`${API_URL_UPDATE}/${id}`,{is_approved:isapproved});
});


// ------------------------------------------- REDUCER -------------------------------------------
export default handleActions(
    {

      [approveTopUps.START]: (state, { payload }) => set(state, 'loading', true),

      [approveTopUps.SUCCESS]: (state, { payload }) =>
                dotProp(state)
                .set('approveData', payload)
                .value(),
    },
    INITIAL_STATE
);
