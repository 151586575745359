import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";

import { Table, Typography, Tag, Tooltip, Button, Icon, Row, Col } from "antd";

import { getStatusTagColor } from "../../core/utils";

const { Text } = Typography;

const StyledTable = styled(Table)`
  .ant-table-placeholder {
    border-bottom: 0px;
  }
  background-color: #fff;
`;

const StyledTag = styled(Tag)`
  text-align: center;
  width: 108px;
  margin-top: 2px;
`;

class ExternalPayTransactionList extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: (text, data) => (
          <span>
            <Text>
              {moment
                .utc(data.date)
                .local()
                .format("YYYY-MM-DD")}
            </Text>
            <br />
            <Text type="secondary">
              {moment
                .utc(data.date)
                .local()
                .format("hh:mm a")}
            </Text>
          </span>
        )
      },
      {
        title: "Transaction No",
        dataIndex: "transaction_id",
        key: "transaction_id"
      },
      {
        title: "Wallet/Alias",
        dataIndex: "alias",
        key: "alias"
      },
      {
        title: "Wallet Address",
        dataIndex: "wallet_address",
        key: "wallet_address"
      },
      {
        title: "Currency",
        dataIndex: 'currency_code',
        key: 'currency_code',
      },
      {
        title: "Requested Amount",
        dataIndex: "crypto_amount",
        key: "crypto_amount",
        align: "right",
        render: (text, data) => (
            <span>
                <Text>
                    {data.crypto_amount}
                </Text>
                {data.fiat_currency && (
                    <div>
                        <Text type="secondary">
                            {`${data.fiat_currency}  ${data.fiat_amount}`}
                        </Text>
                    </div>
                )}
          </span>
        )
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 120,
        render: status => (
          <StyledTag color={getStatusTagColor(status)}>
            {status.toUpperCase()}
          </StyledTag>
        )
      },
      {
        title: 'Action',
        key: 'action',
        width: 125,
        render: (text, data) => data.tx_hash ? this.getTxLink(data.tx_hash, data.currency_code) : ''
      },
    ];

    if (process.env.FEE_APPLICABLE == "true") {
      this.columns.splice(5, 0, {
        title: "Fees",
        dataIndex: 'fees',
        key: 'fees',
        align: 'right',
      });
      this.columns.splice(6, 0, {
        title: "Actual Amount",
        dataIndex: 'actual_amount',
        key: 'actual_amount',
        align: 'right',
      });
    }
  }

  expandedRowRender = record => {
    const columns = [
      {
        title: "Ext. Reference No",
        dataIndex: "ext_ref_id",
        key: "ext_ref_id"
      },
      {
        title: "Customer Name",
        dataIndex: "full_name",
        key: "full_name"
      },
      {
        title: "Customer Email",
        dataIndex: "customer_email",
        key: "customer_email"
      },
      {
        title: "Receiving Wallet Address",
        dataIndex: "wallet_address",
        key: "wallet_address",
        render: (text, data) => (
          <span>
            <a href={data.wallet_url} target="_blank">
              {data.wallet_address}
            </a>
          </span>
        )
      },
      { 
        title: 'Transaction Hash', 
        dataIndex: 'tx_hash', 
        key: 'tx_hash',
        render: (text, data) => (
            <span>
              <a href={data.tx_link} target="_blank">
                {data.tx_hash}
              </a>
            </span>
        )
      },
    ];

    const data = [];
    var txLink = this.generateTxLink(record.tx_hash, record.currency_code);

    data.push({
      ext_ref_id: record.ext_ref_id,
      full_name: record.full_name,
      customer_email: record.customer_email,
      wallet_address: record.wallet_address,
      wallet_url: record.wallet_url,
      tx_hash: record.tx_hash,
      tx_link: txLink.link
    });

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    );
  };

    getTxLink = (txHash, currencyCode) => {
        var txLink = this.generateTxLink(txHash, currencyCode);

        return (
            <span>
                <Tooltip title={txLink.title}>
                    <Button 
                        shape="circle"
                        style={{ backgroundColor: '#0e5bdedb', color: '#ffffff' }}
                        disabled={!txHash}
                        href={txLink.link}
                        target='_blank'
                    >
                      <Icon type="link" />
                    </Button>
                  </Tooltip>
              </span>
        ); 
    };

    generateTxLink = (txHash, currencyCode) => {
        let link = '';
        let title = '';
        if (currencyCode === 'USDT') {
            if (process.env.ETHEREUM_ENV !== 'ropsten') {
                link = 'https://etherscan.io/tx/' + txHash;
            } else {
                link = 'https://ropsten.etherscan.io/tx/' + txHash;
            }
            title = "View in Etherscan"; 
        } else if (currencyCode === 'USDT-TRC20') {
            link = 'https://tronscan.io/#/transaction/' + txHash;
            title = "View in Tronscan";
        } else if (currencyCode === 'BTC') {
            link = 'https://www.blockchain.com/btc/tx/' + txHash;
            title = "View in Blockchain";
        }
        return { link, title };
    }

  render() {
    return (
      <Row>
        <Col xs={0} md={24}>
          <StyledTable
            bordered={true}
            loading={this.props.loading}
            columns={this.columns}
            dataSource={this.props.data}
            rowKey="transaction_id"
            pagination={{
              onChange: page => {
                this.props.handleFetchData(page);
              },
              current: this.props.pagination.current_page,
              total: this.props.pagination.total,
              pageSize: this.props.pagination.per_page
            }}
            expandedRowRender={record => this.expandedRowRender(record)}
            rowClassName="show"
          />
        </Col>
      </Row>
    );
  }
}

export default ExternalPayTransactionList;
