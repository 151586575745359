import React, { Component } from "react"
import { connect } from "react-redux"
import { Card, Row, Col, Button, Divider, Radio, Input, Typography, Checkbox } from "antd"

import { getTransactionDetails, fireCallback, clearData } from "../ducks/webhook"
import { TransactionDetails } from "../components"
import { webhookAllowedGroups, webhookAllowedSuperAdminWallets } from "../../core/config"

const { Text } = Typography

class WebhookContainer extends Component {
  constructor(props) {
    super(props)

    this.form = []
    this.state = {
      webhookAllowedSuperAdminWallets,
      webhookAllowedGroups,
      searchType: 'id',
      searchText: '',
      force: false
    }
  }

  componentWillUnmount() {
    this.onClear()
  };

  onSearchTypeChange = ({ target }) => {
    this.setState({
      searchType: target.value,
      searchText: '',
      force: false
    })
    this.props.clearData()
  };

  onSearchTextChange = ({ target }) => {
    this.setState({
      searchText: target.value,
      force: false
    })
  };

  onGetDetails = () => {
    let payload = {
      type: this.state.searchType,
      text: this.state.searchText
    }
    this.props.getTransactionDetails(payload)
  };

  fireCallback = (isPending) => {
    const { data: { id }, fireCallback } = this.props
    let payload = {
      force: this.state.force,
      isPending
    }
    fireCallback({ id, payload, msgSuccess: "Callback fired successfully" }).then(response => {
      if (response && response.status === 200) {
        this.onClear()
      }
    })
  };

  onClear = () => {
    this.setState({
      searchType: 'id',
      searchText: '',
      force: false
    })
    this.props.clearData()
  };

  onCheckChange = ({ target }) => {
    this.setState({
      force: target.checked
    })
  };

  render() {
    const { currentUser, loading, data } = this.props
    const { webhookAllowedSuperAdminWallets, webhookAllowedGroups, searchType, searchText, force } = this.state

    if (currentUser && !(webhookAllowedSuperAdminWallets.includes(currentUser.wallet.id) || webhookAllowedGroups.includes(currentUser.wallet.wallet_group_id))) {
      return null
    }

    return (
      <div>
        <Row>
          <Col xs={{ span: 24, offset: 0 }} lg={{ span: 18, offset: 3 }} xxl={{ span: 14, offset: 5 }}>
            <Card>
              <Row type="flex" justify="space-around" align="middle">
                <Col md={24} lg={5}>
                  <Text style={{ fontSize: 16, fontWeight: 600 }}>Find Transaction By</Text>
                </Col>
                <Col md={24} lg={19}>
                  <Radio.Group name="searchType" value={searchType} onChange={this.onSearchTypeChange} disabled={loading}>
                    <Radio value="id">Transaction id</Radio>
                    <Radio value="hash">Transaction hash</Radio>
                    <Radio value="refId">Reference Id (Only For Withdrawals)</Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <br />
              <Row gutter={8}>
                {searchType == 'id' && (
                  <Col sm={24} md={8}>
                    <Input value={searchText} placeholder={`Enter Transaction ID`} onChange={this.onSearchTextChange} disabled={loading} />
                  </Col>
                )}
                {searchType == 'hash' && (
                  <Col sm={24} md={16}>
                    <Input value={searchText} placeholder={`Enter Transaction Hash`} onChange={this.onSearchTextChange} disabled={loading} />
                  </Col>
                )}
                {searchType == 'refId' && (
                  <Col sm={24} md={12}>
                    <Input value={searchText} placeholder={`Enter Reference Id`} onChange={this.onSearchTextChange} disabled={loading} />
                  </Col>
                )}
                <Col sm={24} md={8}>
                  <Button onClick={this.onGetDetails} loading={loading} disabled={loading}>Get Transaction</Button>
                </Col>
              </Row>
              <Divider />
              <TransactionDetails data={data} />
              <br />
              {data && (
                <Row type="flex" align="middle" justify="center">
                  <Col xs={24} md={24}>
                    <div className="is-clearfix">
                      <Button
                        type="primary"
                        onClick={() => this.fireCallback(false)}
                        className="is-pulled-right"
                        loading={loading}
                        disabled={!data.is_callback_enabled || data.status !== 'Completed' || (!force && data.callback_status)}
                      >
                        Fire Completed Callback
                      </Button>
                      {data.type === 'Deposit' &&
                        <Button
                          type="primary"
                          onClick={() => this.fireCallback(true)}
                          className="is-pulled-right margin-right-5"
                          loading={loading}
                          disabled={true}
                        // disabled={!data.is_callback_enabled || data.status !== 'Completed' || (!force && data.pending_callback_status)}
                        >
                          Fire Pending Callback
                        </Button>
                      }
                      <Button
                        type="default"
                        onClick={this.onClear}
                        disabled={loading}
                        className="is-pulled-right margin-right-5"
                      >
                        Clear
                      </Button>
                      <Checkbox className="is-pulled-right" checked={force} onChange={this.onCheckChange} style={{ marginRight: 16, marginTop: 8 }} disabled={!data.is_callback_enabled || (!data.callback_status && !data.pending_callback_status)}>Force callback</Checkbox>
                    </div>
                  </Col>
                </Row>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = ({
  webhook: { webhook: { loading, data } }, userProfile: { currentUser }
}) => ({
  loading, data, currentUser
})

export default connect(
  mapStateToProps,
  { getTransactionDetails, fireCallback, clearData }
)(WebhookContainer)
