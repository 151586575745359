import { Form, Input, Row, Col, Button, Card } from "antd";

class ChangePwdForm extends React.Component {
  state = {
    confirmDirty: false
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, handleChangePwd, onClose } = this.props;

    return (
        <Row>
            <Col xs={24} md={12}>
                <Card>
                    <p className="title is-5">Change Password</p>
                    <Form
                        layout="vertical"
                        labelAlign="left"
                    >
                        <Form.Item label="Current Password">
                            {getFieldDecorator("old_password", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input your current password!"
                                    }
                                ]
                            })(<Input.Password />)}
                        </Form.Item>
                        <Form.Item label="New Password">
                            {getFieldDecorator("password", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input your new password!"
                                    },
                                    {
                                        validator: this.validateToNextPassword
                                    }
                                ]
                            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                        </Form.Item>
                        <Form.Item label="Confirm Password" hasFeedback>
                            {getFieldDecorator("password_confirmation", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please confirm your password!"
                                    },
                                    {
                                        validator: this.compareToFirstPassword
                                    }
                                ]
                            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                        </Form.Item>
                        <div style={{ marginTop: '24px' }}>
                            <Row type="flex" align="middle" justify="center">
                                <Col xs={24} md={24}>
                                    <div className="is-clearfix">
                                        <Button
                                            type="primary"
                                            onClick={handleChangePwd}
                                            className="is-pulled-right"
                                            loading={loading}
                                        >
                                            Save 
                                        </Button>
                                        <Button
                                            type="default"
                                            onClick={onClose}
                                            disabled={loading}
                                            className="is-pulled-right margin-right-5"
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
  }
}

ChangePwdForm = Form.create({ name: "change_pwd_form" })(
    ChangePwdForm
);

export default ChangePwdForm;
