import React from 'react';
import { message } from 'antd';
import Router from 'next/router';

const unauthorizedAlert = () => next => action => {
    const { payload } = action;

    if (!payload) {
        return next(action);
    }

    const { status } = payload;

    if (status === 401) {
        const statusText = payload.data?.message || 'Unauthenticated';
        message.error(<span>{statusText}</span>, 8);
        const page = Router.router.asPath.includes('/login') ? '/wallets' : Router.router.asPath === '/' ? '/wallets' : Router.router.asPath;
        Router.push({ pathname: '/login', query: { redirectUrl: page } });

        return;
    }
    let apisNotRedirectPage = [`${process.env.GCX_API_URL}/api/v1/google2fa/verify`];
    //if (status === 403 && !apisNotRedirectPage.includes(payload.config.url)) {
      //  Router.push('/unauthorized');
        //return;
    //}

    return next(action);
};

export default unauthorizedAlert;
