import { Card, Statistic, Row, Col } from "antd";

class BalanceInfoPanel extends React.Component {
  render() {
    const { data, loading } = this.props;

    return (
      <div>
        <Card loading={loading}>
          <Row gutter={16} type="flex" justify="end">
            <Col xs={12} md={6} span={6} offset={18}>
              <Statistic title="Sub Wallets Total Balance" value={Number(data.total_balance)} precision={4} valueStyle={{ fontSize: 18 }}/>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

export default BalanceInfoPanel;
