import dotProp, { set } from "dot-prop-immutable-chain";
import { handleActions } from "redux-actions";
import axios from "axios";
import moment from 'moment';
import { asyncActions } from "modules/core/utils";
import { API_URL, EXPORT_URL } from "../constants";

const INITIAL_STATE = {
  data: [],
  pagination: null,
  loading: false,
  error: null
};

// ------------------------------------------- ACTIONS -------------------------------------------
export const fetchExternalTransactions = asyncActions(
  "TRANSACTIONS/FETCH_EXTERNAL_TRANSACTIONS",
  data => {
    return axios.get(`${API_URL}/external-topups`, { params: data });
  }
);

export const fetchExternalPayTransactions = asyncActions(
  "TRANSACTIONS/FETCH_EXTERNAL_PAY_TRANSACTIONS",
  data => {
    return axios.get(`${API_URL}/external-pay`, { params: data });
  }
);

export const exportPayTransactions = asyncActions('TRANSACTIONS/EXPORT_PAY_TRANSACTIONS', (data) => {
    return axios.get(`${EXPORT_URL}`, { params: data, responseType: 'blob'});
  });

// ------------------------------------------- REDUCER -------------------------------------------
export default handleActions(
  {
    [fetchExternalTransactions.START]: (state, { payload }) =>
      set(state, "loading", true),

    [fetchExternalTransactions.SUCCESS]: (state, { payload }) => {
      const {
        data,
        meta: { pagination }
      } = payload.data;

      return dotProp(state)
        .set("data", data)
        .set("pagination", pagination)
        .set("loading", false)
        .set("error", null)
        .value();
    },

    [fetchExternalPayTransactions.START]: (state, { payload }) =>
      set(state, "loading", true),

    [fetchExternalPayTransactions.SUCCESS]: (state, { payload }) => {
      const {
        data,
        meta: { pagination }
      } = payload.data;

      return dotProp(state)
        .set("data", data)
        .set("pagination", pagination)
        .set("loading", false)
        .set("error", null)
        .value();
    },

    [exportPayTransactions.START]: (state) => set(state, 'loading', true),

    [exportPayTransactions.SUCCESS]: (state, response) => {
      const { payload: { data } } = response;
      let fileName = `Pay Export - ${moment().format('L LT')}`;
      saveAs(data, fileName);

      return dotProp(state)
        .set("loading", false)
        .value();
    },
  },
  INITIAL_STATE
);
