import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Button, Result } from 'antd';

import { TransactionUpdateForm } from '../components';
import { updateExternal, clearUpdateExternal } from '../ducks/update';

class TransactionUpdateContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAmountRequired: true,
    }
  }

  componentWillUnmount() {
    this.props.clearUpdateExternal();
  }

  onStatusChange = (value) => {
    let amount = '';
    
    if (value == 'Mismatch') {
      this.setState({ isAmountRequired: true });
    } else {
      this.setState({ isAmountRequired: false });
    }

    if (value == 'Completed') {
      amount = this.props.transactionData.crypto_amount.split(' ')[1].replace(',', '');
    }
    this.form.props.form.setFieldsValue({ received_amount: amount });
  }

  onUpdateExternal = () => {
    this.form.props.form.validateFieldsAndScroll((err, data) => {
      if (!err) {
        const updateData = {
          id: this.props.transactionData.id,
          status: data.status,
          received_amount: data.received_amount,
        }
        this.props.updateExternal({
          data: updateData,
          msgSuccess: "Transaction Updated Successfully"
        }).then(() => {
          this.props.onCancel();
          this.props.handleFetchData();
        });
      }
    });
  }

  render() {
    const { update, onCancel, transactionData } = this.props;

    const errorMessage = update.error && update.errorMsg ?
      <p className="title is-6 has-text-danger has-text-centered">{update.errorMsg}</p>
      : null;

    const updateButton = !update.success &&
      <Button 
        type="primary" 
        onClick={this.onUpdateExternal}
        loading={update.loading} 
        className="is-pulled-right">
        Update
      </Button>

    const closeButton = !update.success &&
      <Button 
        type="default" 
        onClick={onCancel} 
        disabled={update.loading}
        className="is-pulled-right margin-right-5">
        Close
      </Button>

    const updateForm = !update.success && <TransactionUpdateForm
      wrappedComponentRef={form => {
        this.form = form;
      }}
      onStatusChange={this.onStatusChange}
      isAmountRequired={this.state.isAmountRequired}
      transactionData={transactionData}
      onInputNumberChange={this.onInputNumberChange}
    />;

    return (
      <div>
        <h5 className="subtitle">Update External Transaction</h5>
        <Divider />

        {updateForm}
        {errorMessage}
        <Divider />

        <div className="is-clearfix">
          {updateButton}
          {closeButton}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ external: { update } }) => ({ update });

export default connect(mapStateToProps, { updateExternal, clearUpdateExternal })(TransactionUpdateContainer);