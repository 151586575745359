import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Router from 'next/router';

const withLoginRequired = WrappedComponent => {
    class LoginRequired extends Component {
        componentDidMount() {
            const { accessToken, currentUser } = this.props;
            if (!accessToken || (!currentUser || !currentUser.role || currentUser.role !== 'admin')) {
                const page = Router.router.asPath === '/login' ? '/wallets' : Router.router.asPath === '/' ? '/wallets' : Router.router.asPath;
                Router.push({ pathname: '/login', query: { redirectUrl: page } });
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = ({ userProfile: { accessToken, currentUser } }) => ({ accessToken, currentUser });

    LoginRequired.propTypes = {
        accessToken: PropTypes.any
    };

    LoginRequired.defaultProps = {
        accessToken: null
    };

    return connect(
        mapStateToProps,
        null
    )(LoginRequired);
};

export default withLoginRequired;
