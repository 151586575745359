import axios from 'axios';
import { createAction } from 'redux-actions';
import jsCookie from 'js-cookie';
import moment from 'moment-timezone';
import numeral from 'numeral';
import cloneDeep from 'lodash';

import { Modal, Tooltip, Button, Icon } from 'antd';

const FormData = require('form-data');

const fetchPublicAccessToken = async () => {
    const data = new FormData();
    data.append('grant_type', `${process.env.WHOLESALE_PUBLIC_GRANT_TYPE || 'client_credentials'}`);
    data.append(
        'client_secret',
        `${process.env.WHOLESALE_PUBLIC_CLIENT_SECRET || '4d6357906c0b0fed85b56272e540489357b68a8d'}`
    );
    data.append('client_id', `${process.env.WHOLESALE_PUBLIC_CLIENT_ID || 9}`);
    const res = await axios({
        method: 'post',
        url: `${process.env.WHOLESALE_API_URL}/oauth/token`,
        data
    })
        .then(response => {
            // handle success
            jsCookie.set('public_access_token', response.data.access_token, { expires: 1 });
        })
        .catch(err => {
            // handle error
            console.error(err);
        });

    return res;
};

export const asyncActions = (type, requestFunc, isPublic = false) => {
    const TYPE_START = `${type}_START`;
    const TYPE_SUCCESS = `${type}_SUCCESS`;
    const TYPE_FAILURE = `${type}_FAILURE`;

    const actions = {
        [TYPE_START]: createAction(TYPE_START, input => input, input => input),
        [TYPE_SUCCESS]: createAction(TYPE_SUCCESS, (input, response) => response, (input, response) => input),
        [TYPE_FAILURE]: createAction(TYPE_FAILURE, (input, error) => error, (input, error) => input)
    };

    const thunkAction = input => (dispatch, getState) => {
        // get accessToken from store
        let { accessToken } = getState().userProfile;

        if (!accessToken) {
            // get from cookie
            const cookiesJSON = jsCookie.getJSON();
            accessToken = cookiesJSON.accessToken || null;
        }
        // axios.defaults.headers.common = {
        //     "x-csrf-token": "PSyQN3r2vbUHxObWwrC0NQW4arghBpyC5j3J6ypc",
        //     "x-requested-with": "XMLHttpRequest",
        //     "x-xsrf-token":"eyJpdiI6IjlzOENXTGpRZW1GZVlSUTRVczRnWHc9PSIsInZhbHVlIjoiZ2pxbG1sU21CNlU5Vklvc0VjdEFJM054STJ3b0dCeGhuN0RPaHZHbzFPaWkzWStaWk83bnJ6K1pKY25PRWRKbSIsIm1hYyI6IjkzYTQwNmYxOTExMWQ4NDE0NmM5OGY5MTE0ZWYxODcxMTFmZWMzZjkwMzJlNjE1YmI5OWNjNjI0ZjZiMTg5MzkifQ"
        // };

        if (accessToken) {
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        }

        dispatch(actions[TYPE_START](input));

        return requestFunc(input)
            .then(response => {
                dispatch(actions[TYPE_SUCCESS](input, response));
                return response;
            })
            .catch(error => {
                console.log('error', error);
                dispatch(actions[TYPE_FAILURE](input, error.response));
                return error.response;
            });
    };

    thunkAction.START = actions[TYPE_START].toString();
    thunkAction.SUCCESS = actions[TYPE_SUCCESS].toString();
    thunkAction.FAILURE = actions[TYPE_FAILURE].toString();

    const thunkActionPublic = input => async (dispatch, getState) => {
        const publicAccessToken = jsCookie.get('public_access_token');
        if (!publicAccessToken) {
            await fetchPublicAccessToken();
        }
        axios.defaults.headers.common.Authorization = `Bearer ${jsCookie.get('public_access_token')}`;

        dispatch(actions[TYPE_START](input));

        return requestFunc(input)
            .then(response => {
                dispatch(actions[TYPE_SUCCESS](input, response));
                return response;
            })
            .catch(error => {
                console.log('error', error);
                dispatch(actions[TYPE_FAILURE](input, error.response));
                return error.response;
            });
    };

    thunkActionPublic.START = actions[TYPE_START].toString();
    thunkActionPublic.SUCCESS = actions[TYPE_SUCCESS].toString();
    thunkActionPublic.FAILURE = actions[TYPE_FAILURE].toString();

    return isPublic ? thunkActionPublic : thunkAction;
};

const getTimeZone = country => {
    switch (country) {
        case 'TH':
            return 'Asia/Bangkok';
        case 'ID':
            return 'Asia/Jakarta';
        case 'SG':
            return 'Asia/Singapore';
        default:
            return 'Asia/Singapore';
    }
};

export const getLocalDateTime = ({ dateTime, country, shouldFormat = false, formatTemplate, isTimestamp = false }) => {
    if (!dateTime) return null;
    const timezone = getTimeZone(country);
    const utcDateTime = moment.utc(isTimestamp ? moment.unix(dateTime).format() : dateTime);
    const template = formatTemplate || 'YYYY-MM-DD HH:mm:ss';
    return shouldFormat ? moment.tz(utcDateTime, timezone).format(template) : moment.tz(utcDateTime, timezone);
};

export const formatBackendDateTime = dateTime =>
    dateTime
        ? moment(dateTime)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss')
        : null;

export const formatNestedMomentToBackendDateTime = obj => {
    const result = cloneDeep(obj).value();
    const keys = Object.keys(result);
    keys.forEach(key => {
        if (moment.isMoment(result[key])) {
            result[key] = formatBackendDateTime(result[key]);
        } else if (typeof result[key] === 'object' && result[key] !== null) {
            result[key] = formatNestedMomentToBackendDateTime(result[key]);
        }
    });
    return result;
};

export const getCurrency = countryCode => {
    switch (countryCode) {
        case 'THB':
        case 'TH':
            return '฿';
        case 'IDR':
        case 'ID':
            return 'Rp';
        default:
            return '$';
    }
};

export const getAmountWithCurrency = (amount, countryCode) => {
    if (!amount) return null;

    const currency = getCurrency(countryCode);

    return `${currency} ${numeral(amount).format('0,0.00')}`;
};

export const confirmModal = props => Modal.confirm(props);

export const formatFilesAntdPattern = images => {
    if (images && images.length > 0) {
        return images.map((item, index) => ({
            uid: item?.id || 0 - (index + 1),
            status: 'done',
            response: {
                data: item
            },
            fileId: item?.id,
            name: item?.custom_properties?.original_file_name || item?.file_name,
            url: item?.url
        }));
    }

    return images;
};

export const getFileBase64 = file => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onerror = () => {
            reader.abort();
            reject(new DOMException('Problem parsing input file.'));
        };

        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(file);
    });
};

export const getStatusTagColor = status => {
    switch (status.toUpperCase()) {
        case "SUBMITTED":
            return "blue";
        case "PENDING":
        case "PENDINGEXECUTE":
            return "orange";
        case "COMPLETED":
            return "green";
        case "REJECTED":
        case "EXPIRED":
            return "red";
        case "MISMATCH":
        case "INCOMPLETE":
            return "magenta";
        default:
            return "";
    }
};

export const formatCrypto = value => {
    return numeral(value).format("0,0.[0000]");
};

export const validateCryptoInput = (rule, value, callback) => {
    var regexp = /^\d+(\.\d{1,4})?$/;
    if (value && !regexp.test(value)) {
      callback("Please enter a valid number with 4 decimal places");
    } else {
      callback();
    }
};

export const getNetworkLink = (value, currencyCode, type = 'hash') => {
    let link = '';

    if (currencyCode === 'USDT') {
        if (process.env.ETHEREUM_ENV !== 'ropsten') {
            link = `https://etherscan.io/${type == 'hash' ? 'tx' : 'address' }/${value}`;
        } else {
            link = `https://ropsten.etherscan.io/${type == 'hash' ? 'tx' : 'address' }/${value}`;
        }
    } else if (currencyCode === 'USDT-TRC20') {
        link = `https://tronscan.io/#/${type == 'hash' ? 'transaction' : 'address' }/${value}`;
    }
    
    return link;
}

export const getNetworkLinkElement = (value, currencyCode, type = 'hash', simple = true) => {

    let explorer = '';
    if (currencyCode === 'USDT') {
        explorer = "Etherscan"; 
    } else if (currencyCode === 'USDT-TRC20') {
        explorer = "Tronscan";
    }

    let element = simple ? (
        <a href={getNetworkLink(value, currencyCode, type)} target="_blank">{value}</a>
    ) :(
        <span>
            <Tooltip title={`View in ${explorer}`}>
                <Button 
                    shape="circle"
                    style={{ backgroundColor: '#0e5bdedb', color: '#ffffff' }}
                    disabled={!value}
                    href={getNetworkLink(value, currencyCode, type)}
                    target='_blank'
                >
                  <Icon type="link" />
                </Button>
            </Tooltip>
        </span>
    );

    return element;
}