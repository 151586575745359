import React, { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import { Table, Typography, Button } from "antd";

const { Text } = Typography;
const StyledTable = styled(Table)`
  .ant-table-placeholder {
    border-bottom: 0px;
  }
`;

class WalletList extends Component {
  constructor(props) {
    super(props); 
  }

  render() {
    const { data, currency, loading, pagination, handleFetchData, onClick } = this.props;

    const transferAllowedWalletGroups = [3,27,29,30,31,32,34,47,50,51,52,57,58,59,60,61,63,69,71,72,73,74,75,76,77,78,79,80,81,82,83,85];

    let columns = [
        {
            title: "Created On",
            dataIndex: "created_at",
            key: "created_at",
            render: (text, data) => (
              <span>
                <Text>
                  {moment
                    .utc(data.created_at)
                    .local()
                    .format("YYYY-MM-DD")}
                </Text>
                <br />
                <Text type="secondary">
                  {moment
                    .utc(data.created_at)
                    .local()
                    .format("hh:mm a")}
                </Text>
              </span>
            )
          },
          {
            title: "Wallet No",
            dataIndex: "wallet_no",
            key: "wallet_no"
          },
          {
            title: "Wallet Name",
            dataIndex: "name",
            key: "name"
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email"
          },
          {
            title: "Wallet Address",
            dataIndex: "wallet_address",
            key: "wallet_address"
          },
          {
            title: "Currency",
            dataIndex: 'currency_code',
            key: 'currency_code',
          },
          {
            title: "Balance",
            dataIndex: "balance",
            key: "balance",
            align: 'right',
          },
          {
            title: '',
            key: 'action',
            width: 85,
            align: 'center',
            fixed: 'right',
            render: (text, data) => 
              <span>
                  <Button key="add" type="primary" onClick={() => onClick(data.wallet_no, data.wallet_address, transferAllowedWalletGroups.includes(data.wallet_group_id))}>
                        { transferAllowedWalletGroups.includes(data.wallet_group_id) ? "Transfer" : "Send" }
                  </Button>
              </span>
          }
      ];

      return (
          <div>
            <StyledTable
                bordered={true}
                columns={columns}
                dataSource={data}
                loading={loading}
                rowKey="wallet_no"
                rowClassName="table"
                pagination={{
                    onChange: page => {
                    handleFetchData(page);
                    },
                    current: pagination.current_page,
                    total: pagination.total,
                    pageSize: pagination.per_page
                }}
            />
          </div>
      );
  }
}

export default WalletList;
