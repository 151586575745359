import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, first } from 'lodash';

import { PageHeader, Row } from 'antd';

import { TransactionSummary } from 'modules/summary/components';
import { EmptyTransactionList, TxSummaryAdvancedFilter } from 'modules/core/components';
import { fetchWalletList, fetchAddressByWalletId, fetchTransactionsSummary, exportTransactionsSummary } from '../ducks/list';

class TransactionSummaryContainer extends Component {
    
  constructor(props) {
    super(props);

    const { fetchWalletList, currentUser } = this.props;

    this.state = {
      page: 1,
      walletAddressList: [],
      walletId: 0,
      currencyCode: currentUser?.wallet?.currencies.length < 0 ? 'USDT' : first(currentUser?.wallet?.currencies).code,
      currencyList: currentUser?.wallet?.currencies
    };  

    fetchWalletList({ wallet_group_id: currentUser?.wallet?.wallet_group_id });
  }

   componentDidUpdate(nextProps, prevProps) {
    const { walletList } = this.props.list;
    if (nextProps.list.walletList !== walletList) {
        this.setState({ walletId: walletList[0]?.id });
        this.getWalletAddress(walletList[0]?.id, this.state.currencyCode);
    }
   }

   getWalletAddress = (walletId, currency) => {
    const currencyId = this.state.currencyList.find(q => q.code === currency)?.id;
    this.props.fetchAddressByWalletId({walletId, currencyId});
   }

  handleFetchData = page => {
    this.setState({ page: page });
    this.handleFetchTransactions(page);
  };

  fetchTransactions = (data) => {
    const { fetchTransactionsSummary, list: { address } } = this.props;
    const summaryData = {
        ...data,
        currency: this.state.currencyCode,
        walletAddress: address
    }
    fetchTransactionsSummary(summaryData);
  };

  onFilterClick = () => {
    this.handleFetchTransactions(1, this.state.walletId);
  };

  handleFetchTransactions = (page, walletId) => {
    this.form.props.form.validateFieldsAndScroll((err, formData) => {
        if (!err) {
            const data = {
              page,
              ...formData,
              walletId,
              fromDate: (formData.fromDate !== undefined && formData.fromDate !== null) ? formData.fromDate.format("YYYY-MM-DD") : null,
              toDate: (formData.toDate !== undefined && formData.toDate !== null) ? formData.toDate.format("YYYY-MM-DD") : null
            };
  
          this.fetchTransactions(data);
        }
      });
  }

  onClear = () => {
    this.form.props.form.resetFields();
    const data = {
      page: 1,
      walletId: this.state.walletId
    };
    this.fetchTransactions(data);
  }

  onDataChange = (e, type) => {
    if (type === 'currency') {
        this.setState({ ...this.state, currencyCode: e });
        this.form.props.form.setFieldsValue({ currency: e });
        this.getWalletAddress(this.state.walletId, e);
    } else if (type === 'walletId') {
        this.setState({ ...this.state, walletId: e });
        this.form.props.form.setFieldsValue({ wallet: e });
        this.getWalletAddress(e, this.state.currencyCode);
    }
  }

  onExportClick = () => {
    this.form.props.form.validateFieldsAndScroll((err, formData) => {
      if (!err) {
        const data = {
            ...formData,
            fromDate: (formData.fromDate !== undefined && formData.fromDate !== null) ? formData.fromDate.format("YYYY-MM-DD") : null,
            toDate: (formData.toDate !== undefined && formData.toDate !== null) ? formData.toDate.format("YYYY-MM-DD") : null,
            walletId: this.state.walletId,
            currency: this.state.currencyCode
        };

        this.props.exportTransactionsSummary(data);
      }
    });
  };
  
  render() {
    const {
        list: { data, pagination, loading, walletList, address, loadingAddress }
    } = this.props;

    return (
      <div>
        <Row>
            <PageHeader title="Transaction Summary" />
        </Row>
        <br />
        <Row>
            <TxSummaryAdvancedFilter
                wrappedComponentRef={form => {
                    this.form = form;
                }}
                onFilterClick={this.onFilterClick}
                loading={loading}
                currencyList={this.state.currencyList}
                walletList={walletList}
                walletAddress={address}
                onClear={this.onClear}
                onDataChange={this.onDataChange}
                isLoadingAddress={loadingAddress}
                onExportClick={this.onExportClick}
            />
        </Row>
        <br />
        <div>
            {isEmpty(data) ? <EmptyTransactionList /> :
                <TransactionSummary
                    data={data}
                    pagination={pagination}
                    handleFetchData={this.handleFetchData}
                    loading={loading}
                />
            }
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ summary: { list }, userProfile: { currentUser } }) => ({ list, currentUser });

export default connect(mapStateToProps, { fetchWalletList, fetchAddressByWalletId, fetchTransactionsSummary, exportTransactionsSummary })(TransactionSummaryContainer);
