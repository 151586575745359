import React, { useState } from 'react'
import { Input, Button, Table, Checkbox, Card, Tag } from 'antd'
import { connect } from "react-redux"
import { updateWalletGroup, fetchWalletGroups } from "../ducks/list"
import { notification } from 'antd'
import { Icon } from 'antd'

const EditWalletGroupForm = ({
  data,
  setData,
  onClose,
  updateWalletGroup,
  fetchWalletGroups,
}) => {

  const [password, setPassword] = useState('')

  function copyToClipboard(value) {
    navigator.clipboard.writeText(value)
  }

  const handleCheckboxOnChange = (e, raw) => {
    let { currencies } = data
    let currency = currencies.find((item) => item.id === raw.id)
    currency.enabled = e.target.checked
    setData({ currencies })
  }

  const handleInputOnChange = (e, raw, name) => {
    let { currencies } = data
    let currency = currencies.find((item) => item.id === raw.id)
    if (!currency.pivot) {
      currency.pivot = {}
    }
    currency.pivot[name] = e.target.value
    if (e.target.value) {
      currency.enabled = true
    }
    setData({ currencies })
  }

  const getCheckboxValue = (raw) => {
    let { currencies } = data
    let currency = currencies.find((item) => item.id === raw.id)
    if (currency) {
      return currency.enabled
    }
    return false
  }

  const getInputValue = (raw, name) => {
    let { currencies } = data
    let currency = currencies.find((item) => item.id === raw.id)
    if (currency.pivot) {
      return currency.pivot[name]
    }
    return ''
  }

  const handleOnSave = async () => {
    if (!data.name || !data.user.email) {
      notification.error({
        message: 'Please fulfill your data'
      })
      return
    }

    data.password = password

    const response = await updateWalletGroup({
      ...data,
      msgSuccess: `You have ${data.mode == 'add' ? 'created' : 'updated'} a wallet group successfully`
    })
    if (response.status == 200) {
      fetchWalletGroups()
      if (data.mode == 'add') {
        const { client, user } = response.data.data
        setData({ client, user, isSuccessed: true })
      } else {
        onClose()
      }
    }
  }

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: '22%',
    },
    {
      title: "Deposit rate",
      key: "deposit_rate",
      width: '15%',
      render: (raw) => (
        <div><Input onChange={(e) => handleInputOnChange(e, raw, 'deposit_margin')} value={getInputValue(raw, 'deposit_margin')} /></div>
      )
    },
    {
      title: "Pay rate",
      key: "pay_rate",
      width: '15%',
      render: (raw) => (
        <div><Input onChange={(e) => handleInputOnChange(e, raw, 'pay_margin')} value={getInputValue(raw, 'pay_margin')} /></div>
      )
    },
    {
      title: "Widthdraw rate",
      key: "withdraw_rate",
      width: '15%',
      render: (raw) => (
        <div><Input onChange={(e) => handleInputOnChange(e, raw, 'withdraw_margin')} value={getInputValue(raw, 'withdraw_margin')} /></div>
      )
    },
    {
      title: "Withdraw gas fee",
      key: "fixed_withdraw_fee",
      width: '15%',
      render: (raw) => (
        <div><Input onChange={(e) => handleInputOnChange(e, raw, 'fixed_withdraw_fee')} value={getInputValue(raw, 'fixed_withdraw_fee')} /></div>
      )
    },
    {
      title: "Transfer gas fee",
      key: "fixed_transfer_fee",
      width: '15%',
      render: (raw) => (
        <div><Input onChange={(e) => handleInputOnChange(e, raw, 'fixed_transfer_fee')} value={getInputValue(raw, 'fixed_transfer_fee')} /></div>
      )
    },
    {
      title: "Enabled?",
      key: "action",
      width: '1%',
      render: (raw) => (
        <div align='center'><Checkbox onChange={(e) => handleCheckboxOnChange(e, raw)} checked={getCheckboxValue(raw)} /></div>
      )
    }
  ]

  const showForm = () => (
    <div>
      <div><b>Wallet group name</b> <span style={{ color: 'red' }}>*</span></div>
      <div><Input onChange={(e) => setData({ name: e.target.value })} value={data.name} /></div>

      {data.client && (
        <div>
          <div style={{ marginTop: '2em' }}><b>Client email address</b> <span style={{ color: 'red' }}>*</span></div>
          <div><Input onChange={(e) => setData({ user: { ...data.user, email: e.target.value } })} value={data.user.email} /></div>
        </div>
      )}
      {data.mode == 'edit' && data.client && (
        <div>
          <div style={{ marginTop: '1em', color: 'green' }}><b>Client ID</b></div>
          <div style={{ display: 'flex' }}>
            <Input disabled value={data.client.id} style={{ color: 'gray' }} />
            <Button onClick={() => copyToClipboard(data.client.id)}><Icon type="copy" /></Button>
          </div>

          <div style={{ marginTop: '1em', color: 'green' }}><b>Client secret</b></div>

          <div style={{ display: 'flex' }}>
            <Input disabled value={data.client.secret} style={{ color: 'gray' }} />
            <Button onClick={() => copyToClipboard(data.client.secret)}><Icon type="copy" /></Button>
          </div>
        </div>
      )}
      <div style={{ marginTop: '2em' }}><b>Available currencies</b></div>
      <div>
        <Table
          bordered={true}
          columns={columns}
          dataSource={data.currencies}
          rowKey="id"
          rowClassName="table"
          pagination={false}
        />
      </div>

      <div style={{ marginTop: '2em', display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button style={{ marginLeft: '1em' }} onClick={handleOnSave}>Save</Button>
      </div>
    </div>
  )

  const showSucessMessage = () => (
    <div>
      <div align='center' style={{ fontSize: '1.5em' }}>You have created a wallet group successfully</div>
      <Card style={{ marginTop: '1em' }}>
        <div style={{ display: 'flex', margin: '0.5em', padding: '0.5em', borderBottom: '1px solid #e9e9e9' }}>
          <div style={{ width: '30%' }}>Wallet group name</div>
          <div style={{ width: '70%' }}><Tag color="blue">{data.name}</Tag></div>
        </div>
        <div style={{ display: 'flex', margin: '0.5em', padding: '0.5em', borderBottom: '1px solid #e9e9e9' }}>
          <div style={{ width: '30%' }}>Admin email</div>
          <div style={{ width: '70%' }}><Tag color="blue">{data.user.email}</Tag> <Icon onClick={() => copyToClipboard(data.user.email)} type="copy" /></div>
        </div>
        <div style={{ display: 'flex', margin: '0.5em', padding: '0.5em', borderBottom: '1px solid #e9e9e9' }}>
          <div style={{ width: '30%' }}>Admin password</div>
          <div style={{ width: '70%' }}><Tag color="blue">{data.user.passwd}</Tag> <Icon onClick={() => copyToClipboard(data.user.passwd)} type="copy" /></div>
        </div>
        <div style={{ display: 'flex', margin: '0.5em', padding: '0.5em', borderBottom: '1px solid #e9e9e9' }}>
          <div style={{ width: '30%' }}>Client ID</div>
          <div style={{ width: '70%' }}><Tag color="blue">{data.client.id}</Tag> <Icon onClick={() => copyToClipboard(data.client.id)} type="copy" /></div>
        </div>
        <div style={{ display: 'flex', margin: '0.5em', padding: '0.5em' }}>
          <div style={{ width: '30%' }}>Client secret</div>
          <div style={{ width: '70%' }}><span style={{ fontSize: '12px' }}>{data.client.secret}</span> <Icon onClick={() => copyToClipboard(data.client.secret)} type="copy" /></div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0.5em' }}>
          <Button type='primary' onClick={() => setData({ isSuccessed: false }) & onClose()}>Finish</Button>
        </div>
      </Card>
    </div>
  )

  return (
    <div>
      {data.isSuccessed ? showSucessMessage() : showForm()}
    </div>
  )
}

const mapdataToProps = (state) => ({
})
export default connect(mapdataToProps, { updateWalletGroup, fetchWalletGroups })(EditWalletGroupForm)