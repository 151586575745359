import { combineReducers } from 'redux';

import reducerList, * as actionsList from './list';
import reducerApprove, * as actionsApprove from './approve';
import reducerReject, * as actionsReject from './reject';

const reducer = combineReducers({
  list: reducerList,
  approve: reducerApprove,
  reject: reducerReject,
});

export const actions = {
  list: actionsList,
  approve: actionsApprove,
  reject: actionsReject,
};

export default reducer;
