import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { Table, Divider, Icon, Button, Tooltip, Typography, Tag } from 'antd';

import { approveCashOuts } from '../ducks/approve';
import { rejectCashOuts } from '../ducks/reject';
import { fetchCashOuts } from '../ducks/list';

import { getStatusTagColor } from '../../core/utils';

const { Text } = Typography;
const StyledTable = styled(Table)`
    .ant-table-placeholder{
        border-bottom: 0px;
    }
`;

const StyledTag = styled(Tag)`
    text-align: center;
    width: 108px;
    margin-top: 2px;
`;

// const CashOutList = ({data, pagination, onFetchData}) =>
//     <StyledTable
//         bordered={true}
//         columns={columns}
//         dataSource={data}
//         rowKey="id"
//         rowClassName="table"
//         // onRow={(record, rowIndex) => {
//         //     return {
//         //         onClick: event => {Router.push('/accounts/details')}, // click row
//         //     };
//         // }}
//     />

class CashOutList extends Component{

  constructor(props) {
      super(props);
      this.state = {
        isSubmitting: false,
        isSubmittingId: '',
      };
      this.cashoutData=this.props.data;
      this.columns = [
          {
              title: "Date",
              dataIndex: 'date',
              key: 'date',
              render: (text, data) => (
                <span>
                  <Text>{moment.utc(data.date).local().format('YYYY-MM-DD')}</Text>
                  <br />
                  <Text type="secondary">{moment.utc(data.date).local().format('hh:mm a')}</Text>
                </span>
              )
          },
          {
              title: 'Transaction No',
              dataIndex: 'id',
              key: 'id',
          },
          {
              title: "Wallet/Alias",
              dataIndex: 'alias',
              key: 'alias',
          },
          // {
          //     title: "Cash Out/Send To",
          //     dataIndex: 'send_to',
          //     key: 'send_to',
          // },
          {
              title: "Cash Out Amount",
              dataIndex: 'amount',
              key: 'amount',
              align: 'right',
          },
          {
            title: "Status",
            dataIndex: 'status',
            key: 'status',
            width: 130,
            render: status => (
              <StyledTag color={getStatusTagColor(status)}>
                {status.toUpperCase()}
              </StyledTag>
            )
          },
          // {
          //     title: "Amount To Receive",
          //     dataIndex: 'amount_to_receive',
          //     key: 'amount_to_receive',
          // },
          // {
          //     title: "Fee",
          //     dataIndex: 'fee',
          //     key: 'fee',
          // },
          {
              title: 'Action',
              key: 'action',
              width: 125,
              render: (text, data) => data.status === 'Pending' ? (
                <span>
                  <Tooltip title="Accept">
                    <Button
                      shape="circle"
                      onClick={() => this.cashoutApprove(data)}
                      style={{ backgroundColor: '#5db85b', color: '#ffffff' }}
                      disabled={data.id === this.state.isSubmittingId && this.state.isSubmitting}
                    >
                      <Icon type="check" />
                    </Button>
                  </Tooltip>
                  <Divider type="vertical" />
                  <Tooltip title="Reject">
                    <Button
                      shape="circle"
                      onClick={() => this.cashoutReject(data)}
                      style={{ backgroundColor: '#da534f', color: '#ffffff' }}
                      disabled={data.id === this.state.isSubmittingId && this.state.isSubmitting}
                    >
                      <Icon type="close" />
                    </Button>
                  </Tooltip>
                </span>
              ) : '',
          },
      ];
  }

  cashoutApprove =(approveData) => {
    this.setState({ isSubmitting: true, isSubmittingId: approveData.id });
    this.props.approveCashOuts(
      {
        id:approveData.id,
        isapproved:1,
        msgSuccess: 'Accepted successfully'
      }

    ).then(
            () => {
                const { fetchCashOuts } = this.props;
                fetchCashOuts();
            }
    );
  }

  cashoutReject =(rejectData) => {
    this.setState({ isSubmitting: true, isSubmittingId: rejectData.id });
    this.props.rejectCashOuts(
      {
        id:rejectData.id,
        isapproved:0,
        msgSuccess: 'Rejected successfully'
      }

    ).then(
            () => {
                const { fetchCashOuts } = this.props;
                fetchCashOuts();
            }
    );
  }

  render(){
      const { approve: { approveData }, reject: { rejectData }, onFetchData } = this.props;

      return(
          <StyledTable
              bordered={true}
              columns={this.columns}
              dataSource={this.cashoutData}
              rowKey="id"
              rowClassName="table"
              pagination={{
                onChange: page => {
                  onFetchData(page);
                },
                current: this.props.pagination.current_page,
                total: this.props.pagination.total,
                pageSize: this.props.pagination.per_page,
              }}
          />
    );
  }

}

const mapStateToProps = ({ topup: { approve }, topup: { reject }}) => ({ approve, reject });

export default connect(mapStateToProps, { approveCashOuts, rejectCashOuts, fetchCashOuts })(CashOutList);
