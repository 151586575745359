import dotProp, { set } from 'dot-prop-immutable-chain';
import { handleActions } from 'redux-actions';
import axios from 'axios';
import { asyncActions } from 'modules/core/utils';
import { API_URL } from '../constants';

const INITIAL_STATE = {
    approveData: null,
    errors: ''
};
// ------------------------------------------- ACTIONS -------------------------------------------

export const approveWithdraw = asyncActions('TRANSACTION/APPROVE_WITHDRAW', ({ id , isapproved}) =>{
  return axios.put(`${API_URL}/${id}`,{is_approved:isapproved});
});


// ------------------------------------------- REDUCER -------------------------------------------
export default handleActions(
    {

        [approveWithdraw.START]: (state, { payload }) => set(state, 'loading', true),

        [approveWithdraw.SUCCESS]: (state, { payload }) =>
                dotProp(state)
                .set('approveData', payload)
                .value(),

        [approveWithdraw.FAILURE]: (state, { payload }) =>
                dotProp(state)
                  .set('approveData', null)
                  .set('errors', payload.data.message)
                  .value(),
    },
    INITIAL_STATE
);
