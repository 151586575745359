import React from "react";
import { Form, Input, Row, Col, Button } from "antd";
import styled from "styled-components";

const StyledDiv = styled.div`
  @media only screen and (min-width: 768px) {
    .search-form .ant-form-item.search-form-input{
      width: 500px;
    }

    .search-form .ant-form-item-control-wrapper {
      width: 100%;
    }
  }
`;

class SearchForm extends React.Component {

  render() {
    const { onSearch, onClearSearch, form: { getFieldDecorator } } = this.props;

    return (
      <StyledDiv>
        <Form layout="inline" className="search-form" ref={this.wrappedComponentRef}>
          <Row gutter={24} justify="center">
            <Col xs={24} style={{ textAlign: "right" }}>
              <Form.Item className="search-form-input">
                {getFieldDecorator('search', {})(<Input allowClear onChange={onClearSearch} placeholder="Search by Wallet Address / Wallet No / Wallet Name" />)}
              </Form.Item>
              <Form.Item style={{ marginRight: 0 }}>
                <Button type="primary" htmlType="submit" style={{ marginTop: '3px' }} onClick={onSearch}>
                  Search
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </StyledDiv>
    );
  }
}

SearchForm = Form.create({ name: "search_form" })(
    SearchForm
);

export default SearchForm;
