import React from 'react'
import { Input, Button, Row, Col } from 'antd'

const initState = {
  count: 0,
}

const MultipleInput = ({ value = [], onChange }) => {
  const addInput = () => {
    if (value.length < 5) {
      value.push('')
      onChange(value)
    }
  }

  const removeInput = (key) => {
    value.forEach((item, index) => {
      if (key == index) {
        value.splice(index, 1)
      }
    })
    onChange(value)
  }

  const handleInputChange = (key, event) => {
    value[key] = event.target.value
    onChange(value)
  }

  const showInputs = () => {
    if (value.length == 0) {
      addInput()
    }
    return value.map((v, key) => {
      return (
        <Row key={key} style={{ marginBottom: '1em' }}>
          <Col xs={20}><Input value={v} onChange={(e) => handleInputChange(key, e)} /></Col>
          <Col xs={2}>{key + 1 == value.length && <Button style={{ color: 'gray', marginLeft: '5px' }} onClick={addInput}>+</Button>}</Col>
          <Col xs={2}>{key + 1 == value.length && <Button style={{ color: 'gray', marginLeft: '5px' }} onClick={() => removeInput(key)}>-</Button>}</Col>
        </Row>
      )
    })
  }

  return (
    <div>
      {showInputs()}
    </div>
  )
}

export default MultipleInput