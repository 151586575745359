import React, { Component } from 'react';
import styled from "styled-components";
import moment from 'moment';

import { Card, Row, Col, Form, DatePicker, Button, Input, Select } from 'antd';

const { Option } = Select;

class TxSummaryAdvancedFilter extends Component {
  constructor(props) {
    super(props);
  }

  onValidateDate = (rule, value, callback) => {
    const { form } = this.props;
    const fromdate = form.getFieldValue('fromDate');
    let todate = form.getFieldValue('toDate');
    if (todate == null) {
      todate = '';
    }
    
    if (fromdate && moment(fromdate).format('L') !== moment(todate).format('L')) {
        if (rule.field === 'fromDate') {
            if (moment(value).isAfter(moment(todate))) {
              callback('Invalid date selected');
            }
        } else if (moment(value).isBefore(fromdate)) {
              callback('Invalid date selected');
        }
    } 
    
    callback();
  };
  
  render() {
    const StyledDatePicker = styled(DatePicker)`
      width: 100%;
    `;

    const { getFieldDecorator } = this.props.form;
    const { walletList, onFilterClick, loading, onClear, onDataChange, walletAddress, isLoadingAddress, onExportClick, currencyList } = this.props;

    return (
      <Row>
        <Col>
          <Card>
            <Form layout="horizontal" labelCol={{xs: {span:4}, md: {span:8}, lg: {span:8}}} wrapperCol={{xs: {span:16}, md: {span:16}, lg: {span:16}}}> 
                <Row gutter={[8,8]}>
                    <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                        <Form.Item label="From Date" style={{ display: 'flex' }}>
                            {getFieldDecorator('fromDate', {
                                rules: [
                                    { validator: this.onValidateDate }
                                ]})(
                                <StyledDatePicker placeholder="Select from date" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                        <Form.Item label="To Date" style={{ display: 'flex' }}>
                            {getFieldDecorator('toDate', {
                                rules: [
                                    { validator: this.onValidateDate }
                                ]})(
                                <StyledDatePicker placeholder="Select to date" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                        <Form.Item label="Wallet" style={{ display: 'flex' }}>
                            {getFieldDecorator('walletId', { initialValue: walletList && walletList.length > 0 ? `[${walletList[0].id}] ${walletList[0].name}`: '' })(
                                <Select allowClear onChange={(e) => onDataChange(e, 'walletId')}>
                                    {walletList?.map((item, index) => (
                                        <Option value={item.id} key={index}><strong>[{item.id}]</strong> {item.name}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                        <Form.Item label="Currency" style={{ display: 'flex' }}>
                            {getFieldDecorator('currency', { initialValue: currencyList && currencyList.length > 0 ? currencyList[0].code : '' })(
                                <Select allowClear onChange={(e) => onDataChange(e, 'currency')}>
                                    {currencyList?.map((item, index) => (
                                        <Option key={item.code}>{item.code}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                        <Form.Item label="Transaction Type" style={{ display: 'flex' }}>
                            {getFieldDecorator('type', {  })(
                                <Select placeholder="Select transaction type" allowClear>
                                    <Option key="Deposit">Deposit</Option>
                                    <Option key="Withdraw">Withdraw</Option>
                                    <Option key="Transfer">Transfer</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                        <Form.Item label="Transaction Id" style={{ display: 'flex' }}>
                            {getFieldDecorator('transactionId', { })(
                                <Input placeholder="Enter transaction id" allowClear />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                        <Form.Item label="Transaction Hash" style={{ display: 'flex' }}>
                            {getFieldDecorator('hash', { })(
                                <Input placeholder="Enter transaction hash" allowClear />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} span={6} style={{ display: 'block'}}>
                        <Form.Item label="Wallet Address" style={{ display: 'flex' }} hasFeedback validateStatus={isLoadingAddress && "validating"}>
                            <Input value={!isLoadingAddress ? walletAddress : ''} disabled placeholder={isLoadingAddress ? "Loading wallet address" : '' } />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ textAlign: 'right' }} >
                        <Button type="primary" onClick={onFilterClick} disabled={loading || isLoadingAddress}>Filter</Button>
                        <Button style={{ marginLeft: 8 }} type="primary" onClick={onExportClick} disabled={loading || isLoadingAddress}>Export</Button>
                        <Button style={{ marginLeft: 8 }} onClick={onClear} disabled={loading || isLoadingAddress}>Clear</Button>
                    </Col>
                </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

TxSummaryAdvancedFilter = Form.create({ name: "tx_summary_advanced_filter" })(TxSummaryAdvancedFilter);

export default TxSummaryAdvancedFilter;