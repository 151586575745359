import dotProp, { set } from "dot-prop-immutable-chain";
import { handleActions, createAction } from "redux-actions";
import axios from "axios";
import { asyncActions } from "modules/core/utils";
import { API_URL } from "../constants";

const INITIAL_STATE = {
  data: null,
  loading: false,
};

// ------------------------------------------- ACTIONS -------------------------------------------
export const getTransactionDetails = asyncActions("WALLET/GET_TRANSACTION_DETAILS", data =>
  axios.post(`${API_URL}/transaction/details`, { ...data })
);

export const fireCallback = asyncActions("WALLET/FIRE_CALLBACK", ({ id, payload }) => 
  axios.put(`${API_URL}/transaction/${id}/webhook`, { ...payload })
);

export const clearData = createAction('WALLET/CLEAR_FEE_DATA');

// ------------------------------------------- REDUCER -------------------------------------------
export default handleActions(
  {
    [getTransactionDetails.START]: (state) => set(state, "loading", true),

    [getTransactionDetails.SUCCESS]: (state, { payload }) =>
      dotProp(state)
        .set("data", payload?.data)
        .set("loading", false)
        .value(),

    [getTransactionDetails.FAILURE]: (state) => 
        dotProp(state)
            .set("data", null)
            .set("loading", false)
            .value(),

    [fireCallback.START]: (state) => set(state, "loading", true),

    [fireCallback.SUCCESS]: (state, { payload }) =>
      dotProp(state)
        .set("data", payload?.data)
        .set("loading", false)
        .value(),

    [fireCallback.FAILURE]: (state) => set(state, "loading", false),

    [clearData]: (state) => 
      dotProp(state)
          .set('data', null)
          .value(),
        
  },
  INITIAL_STATE
);
