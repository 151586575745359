import dotProp, { get, set, merge } from 'dot-prop-immutable-chain'
import { createAction, handleActions, combineActions } from 'redux-actions'
import axios from 'axios'
import jsCookie from 'js-cookie'
import { asyncActions } from 'modules/core/utils'
import { API_URL } from '../constants'

const INITIAL_STATE = {
  currentUser: null,
  accessToken: null,
  loading: false,
  changePwdSuccess: null,
  changePwdError: null
}

// ------------------------------------------- ACTIONS -------------------------------------------
export const getSetting = asyncActions('USER_PROFILE/GET_SETTING', data =>
  axios.get(`${API_URL}/users/settings`, data)
)

export const verifyOtp = asyncActions("WALLET/VERIFY_OTP", data =>
    axios.post(`${API_URL}/google2fa/verify`, { ...data })
);

export const changeSetting = asyncActions('USER_PROFILE/CHANGE_SETTING', data =>
  axios.put(`${API_URL}/users/change-setting`, data)
)

export const loginByEmail = asyncActions('USER_PROFILE/LOGIN_BY_EMAIL', ({ email, password, app }) =>
  axios.post(`${API_URL}/loginByEmail`, { email, password, app })
)
export const logout = createAction('USER_PROFILE/LOGOUT', () => {
  jsCookie.remove('accessToken', 'page')
})
export const fetchCurrentUser = asyncActions('USER_PROFILE/FETCH_CURRENT_USER', () =>
  axios.get(`${API_URL}/users/current`)
)
export const changeLanguage = asyncActions('USER_PROFILE/CHANGE_LANGUAGE', ({ id, language }) =>
  axios.put(`${API_URL}/users/${id}/language`, { language })
)
export const updateProfileUser = asyncActions('USER_PROFILE/UPDATE_PROFILE_USER', ({ id, data }) =>
  axios.put(`${API_URL}/users/${id}`, data)
)
export const updateProfileImage = asyncActions('USER_PROFILE/UPDATE_PROFILE_IMAGE', ({ id, base64 }) =>
  axios.put(`${API_URL}/users/${id}/profile-image`, { file: base64 })
)
export const setAccessToken = createAction('USER_PROFILE/SET_ACCESS_TOKEN')

export const updateUserActiveGroup = asyncActions('USER_PROFILE/UPDATE_USER_GROUP', ({ id, data }) =>
  axios.put(`${API_URL}/users/${id}/active-group`, data)
)

export const changePassword = asyncActions('USER_PROFILE/CHANGE_PASSWORD', data =>
  axios.put(`${API_URL}/users/current/password`, data)
)

export const resetPasswordUser = asyncActions('USER_PROFILE/RESET_PASSWORD', ({ data }) =>
  axios.post(`${API_URL}/users/password/reset`, data)
)

export const requestResetPassword = asyncActions('USER_PROFILE/REQUEST_RESET_PASSWORD', ({ data }) =>
  axios.post(`${API_URL}/users/password/request-reset`, data), true
)

// ------------------------------------------- REDUCER -------------------------------------------
export default handleActions(
  {
    [loginByEmail.SUCCESS]: (state, { payload }) => {
      const { user, access_token } = payload.data.data

      return dotProp(state)
        .set('currentUser', user)
        .set('accessToken', access_token)
        .value()
    },

    [fetchCurrentUser.SUCCESS]: (state, { payload }) => {
      const user = payload.data.data

      return dotProp(state)
        .set('currentUser', user)
        .value()
    },

    [updateProfileUser.START]: (state, { payload }) => set(state, 'loading', true),

    [updateProfileUser.SUCCESS]: (state, { payload }) => {
      const user = payload.data.data

      return dotProp(state)
        .set('currentUser', user)
        .value()
    },

    [updateProfileImage.START]: (state, { payload }) => set(state, 'loading', true),

    [updateProfileImage.SUCCESS]: (state, { payload }) =>
      dotProp(state)
        .set('currentUser', payload.data.data)
        .value(),

    [changeLanguage.START]: (state, { payload }) => set(state, 'currentUser.locale.language', payload.language),

    [changeLanguage.SUCCESS]: (state, { payload }) => set(state, 'currentUser', payload.data.data),

    [logout]: (state, { payload }) => INITIAL_STATE,

    [setAccessToken]: (state, { payload }) => set(state, 'accessToken', payload),

    [updateUserActiveGroup.SUCCESS]: (state, { payload }) => set(state, 'currentUser', payload.data.data),

    [changePassword.START]: state =>
      dotProp(state)
        .set('loading', true)
        .value(),

    [changePassword.SUCCESS]: state =>
      dotProp(state)
        .set('loading', false)
        .set('changePwdError', null)
        .set('changePwdSuccess', true)
        .value(),

    [changePassword.FAILURE]: (state, { payload }) =>
      dotProp(state)
        .set('loading', false)
        .set('changePwdError', payload?.data?.message)
        .set('changePwdSuccess', null)
        .value(),

    [changeSetting.START]: state =>
      dotProp(state)
        .set('loading', true)
        .value(),

    [changeSetting.SUCCESS]: state =>
      dotProp(state)
        .set('loading', false)
        .value(),

    [changeSetting.FAILURE]: (state, { payload }) =>
      dotProp(state)
        .set('loading', false)
        .value(),

    [getSetting.START]: state =>
      dotProp(state)
        .set('loading', true)
        .value(),

    [getSetting.SUCCESS]: state =>
      dotProp(state)
        .set('loading', false)
        .value(),

    [getSetting.FAILURE]: (state, { payload }) =>
      dotProp(state)
        .set('loading', false)
        .value(),
  },
  INITIAL_STATE
)
