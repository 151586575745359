import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import { PageHeader, Row } from "antd";

import { ExternalPayTransactionList } from "modules/external/components";
import { EmptyTransactionList, AdvancedFilter } from "modules/core/components";
import { fetchExternalPayTransactions, exportPayTransactions } from "../ducks/list";

class ExternalPayTransactionListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
        page: 1,
    };
  }

  componentDidMount() {
    const { page } = this.state;
    const data = {
        page
    };
    this.fetchPayTransactions(data);
  }

  handleFetchData = page => {
    this.setState({ page: page });
    this.handleFetchTransactions(page);
  };

  fetchPayTransactions = (data) => {
    const { fetchExternalPayTransactions } = this.props;
    const payData = {
        ...data,
        type: 'Pay'
    }
    fetchExternalPayTransactions(payData);
  };

  onFilterClick = () => {
    this.handleFetchTransactions(1);
  };

  handleFetchTransactions = (page) => {
    this.form.props.form.validateFieldsAndScroll((err, formData) => {
        if (!err) {
            const data = {
              page,
              ...formData,
              fromDate: (formData.fromDate !== undefined && formData.fromDate !== null) ? formData.fromDate.format("YYYY-MM-DD") : null,
              toDate: (formData.toDate !== undefined && formData.toDate !== null) ? formData.toDate.format("YYYY-MM-DD") : null
            };
  
          this.fetchPayTransactions(data);
        }
      });
  }

  onClear = () => {
    this.form.props.form.resetFields();
    const data = {
      page: 1,
    };
    this.fetchTransactions(data);
  }

  onExportClick = () => {
    this.form.props.form.validateFieldsAndScroll((err, formData) => {
      if (!err) {
        const data = {
            ...formData,
            fromDate: (formData.fromDate !== undefined && formData.fromDate !== null) ? formData.fromDate.format("YYYY-MM-DD") : null,
            toDate: (formData.toDate !== undefined && formData.toDate !== null) ? formData.toDate.format("YYYY-MM-DD") : null,
        };

        this.props.exportPayTransactions(data);
      }
    });
  };

  render() {
    const {
      list: { data, pagination, loading }
    } = this.props;

    return (
      <div>
        <Row>
            <PageHeader title="External - Pay" />
        </Row>
        <br />
        <Row style={{ marginBottom: 10 }}>
            <AdvancedFilter
                wrappedComponentRef={form => {
                this.form = form;
                }}
                onFilterClick={this.onFilterClick}
                fromDate={this.state.fromDate} 
                loading={loading}
                onExportClick={this.onExportClick}
                exportable
                statusList={['Pending', 'Completed', 'Incomplete']}
                onClear={this.onClear}
                userWalletGroupId={this.props.currentUser && this.props.currentUser.wallet.wallet_group_id}
            />
        </Row>
        <br />
        <Row>
        {isEmpty(data) ? (
            <EmptyTransactionList />
            ) : (
            <ExternalPayTransactionList
            data={data}
            pagination={pagination}
            handleFetchData={this.handleFetchData}
            onMoreClick={this.onMoreClick}
            loading={loading}
            />
        )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ external: { list }, userProfile: { currentUser } }) => ({ list, currentUser });

export default connect(
  mapStateToProps,
  { fetchExternalPayTransactions, exportPayTransactions }
)(ExternalPayTransactionListContainer);
