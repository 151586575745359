import dotProp, { set } from 'dot-prop-immutable-chain';
import { handleActions } from 'redux-actions';
import axios from 'axios';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { asyncActions } from 'modules/core/utils';
import { API_URL, EXPORT_URL } from '../constants';

const INITIAL_STATE = {
  data: [],
  pagination: null,
  loading: false,
  error: null
};

// ------------------------------------------- ACTIONS -------------------------------------------
export const fetchTransferTransactions = asyncActions('TRANSACTIONS/FETCH_TRANSFER_TRANSACTIONS', (data) => {
  return axios.get(`${API_URL}`, { params: data });
});

export const exportTransactions = asyncActions('TRANSACTIONS/EXPORT_TRANSFER_TRANSACTIONS', (data) => {
  return axios.get(`${EXPORT_URL}/transfer`, { params: data, responseType: 'blob'});
});
// ------------------------------------------- REDUCER -------------------------------------------
export default handleActions(
  {
    [fetchTransferTransactions.START]: (state, { payload }) => set(state, 'loading', true),

    [fetchTransferTransactions.SUCCESS]: (state, { payload }) => {
      const {
        data,
        meta: { pagination }
      } = payload.data;

      return dotProp(state)
        .set('data', data)
        .set('pagination', pagination)
        .set('loading', false)
        .set('error', null)
        .value();
    },
    
    [exportTransactions.START]: (state) => set(state, 'loading', true),

    [exportTransactions.SUCCESS]: (state, response) => {
      const { payload: { data } } = response;
      let fileName = `Transfer Export - ${moment().format('L LT')}`;
      saveAs(data, fileName);

      return dotProp(state)
        .set("loading", false)
        .value();
    },
  },
  INITIAL_STATE
);
