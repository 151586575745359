import React from 'react';
import { notification } from 'antd';

const asyncActionMessages = () => next => action => {
    const { type, meta, payload } = action;

    if (!type || !meta || !payload) {
        return next(action);
    }

    if (type.includes('_SUCCESS') && meta && meta.msgSuccess) {
        notification.success({
            message: meta.msgSuccessTitle || 'Success',
            description: meta.msgSuccess,
            duration: 5,
            className: 'notification-success'
        });
    } else if (type.includes('_FAILURE')) {
        const { exception, errors } = payload?.data || {};
        if (meta && meta.msgFailure) {
            notification.error({
                message: meta.msgFailureTitle || 'Something went wrong',
                description: meta.msgFailure,
                duration: 5,
                className: 'notification-error'
            });
        }

        // backend throw errors
        if (errors) {
            let errorMessages = '';

            if (typeof errors === 'string') {
                errorMessages = errors;
            } else {
                errorMessages = Object.values(errors)
                    .flat(1)
                    .map((err, index) => <p key={index}>- {err}</p>);
            }

            notification.error({
                message: 'Something went wrong',
                description: <div className="has-text-left">{errorMessages}</div>,
                duration: 5,
                className: 'notification-error'
            });
        }
        // backend unexpected exception
        else if (exception) {
            notification.error({
                message: 'Something went wrong',
                description: 'Unexpected error. Please contact admin',
                duration: 5,
                className: 'notification-error'
            });
        }
    }

    return next(action);
};

export default asyncActionMessages;
