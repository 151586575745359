import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { Spin, PageHeader, Row, Col } from 'antd';

import { CashOutList } from 'modules/cashout/components';
import { EmptyTransactionList } from 'modules/core/components';
import { fetchCashOuts } from '../ducks/list';

class CashOutListContainer extends Component {
    // componentDidMount() {
    //     const { fetchCashOuts } = this.props;
    //     // fetchCashOuts();
    // }
    //
    // render() {
    //     const {
    //         list: { data, loading }
    //     } = this.props;
    //
    //     if (loading) {
    //         return <Spin size="large" />;
    //     }
    //
    //     return (
    //         <div>
    //             <CashOutList data={[]} />
    //         </div>
    //     )
    // }


    constructor(props) {
        super(props);

        this.state = {
            page: 1,
        };
    }

    componentDidMount() {
        const { fetchCashOuts } = this.props;
        const { page } = this.state;
        const data = { page };
        fetchCashOuts(data);
    }

    cashoutApprove =(approveData) =>{
      console.log(approveData.id);
    }

    handleFetchData = page => {
        this.setState({
            page: page
        });
        const data = { page };
        this.props.fetchCashOuts(data);
    };

    render() {
        const {
            list: { data, pagination, loading }
        } = this.props;

        if (loading) {
            return <Spin size="large" />;
        }

        return (

          <div>
            <Row>
              <Col>
                <PageHeader title="Transactions - Cash Outs" />
              </Col>
            </Row>
            <Row>
              <Col>
                {isEmpty(data) ? <EmptyTransactionList /> :
                  <CashOutList
                    data={data}
                    pagination={pagination}
                    onFetchData={this.handleFetchData}
                  />}
              </Col>
            </Row>
          </div>

        )
    }
}

const mapStateToProps = ({ cashout: { list } }) => ({ list });

export default connect(mapStateToProps, { fetchCashOuts })(CashOutListContainer);
