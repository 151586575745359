import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { Card, Table, Button, Drawer, PageHeader } from 'antd'
import { fetchWalletGroups, fetchAllCurrencies } from '../ducks/list'
import { EditWalletGroupForm } from '../components'

const initState = {
  isSuccessed: false,
  id: '',
  name: '',
  currencies: [],
  user: {},
  client: {
    id: '',
    name: '',
    secret: '',
  },
}

const WalletGroupList = ({
  loading,
  walletGroups,
  currencies,
  fetchWalletGroups,
  fetchAllCurrencies,
  pagination,
}) => {
  const [showEditWalletGroupModal, setEditWalletGroupModal] = useState(false)
  const [currentEditingData, setCurrentEditingData] = useState(initState)

  const resetData = () => {
    setData(initState)
  }

  const setData = (data, mode = '') => {
    if (mode) {
      data.mode = mode
    }
    if (mode == 'add') {
      data.currencies = currencies
    }
    setCurrentEditingData((currentState) => ({ ...currentState, ...data }))
  }

  const toggleModal = () => {
    setEditWalletGroupModal(!showEditWalletGroupModal)
  }

  useEffect(() => {
    fetchAllCurrencies()
    fetchWalletGroups()
  }, [])

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: '90%',
    },
    {
      title: "Action",
      key: "action",
      width: '10%',
      render: (data) => (
        <div><Button onClick={() => setData(data, 'edit') & toggleModal()}>Edit</Button></div>
      )
    }
  ]

  return (
    <div>
      <PageHeader title="Settings - Wallet Groups" />
      <Card style={{ marginTop: '1em' }}>
        <div>
          <Button type="primary" onClick={() => setData(initState, 'add') & toggleModal()}>Add Wallet Group</Button>
        </div>
        <Table
          style={{ marginTop: '1em' }}
          bordered={true}
          columns={columns}
          dataSource={walletGroups}
          loading={loading}
          rowKey="id"
          rowClassName="table"
          pagination={pagination}
        />
      </Card>
      <Drawer visible={showEditWalletGroupModal} width={'60%'} onClose={() => resetData() & setEditWalletGroupModal(!showEditWalletGroupModal)}>
        <EditWalletGroupForm
          data={currentEditingData}
          setData={setData}
          onClose={() => setEditWalletGroupModal(false)}
        />
      </Drawer>
    </div>
  )
}

const mapStateToProps = (state) => ({
  loading: state.loading,
  walletGroups: state.wallet.list.groups,
  currencies: state.wallet.list.currencies,
})

export default connect(mapStateToProps, { fetchWalletGroups, fetchAllCurrencies })(WalletGroupList)