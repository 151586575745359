import React, { Component } from 'react';
import { Form, Select, InputNumber, Descriptions } from 'antd';

const { Option } = Select;

class TransactionUpdateForm extends Component {

  constructor(props) {
    super(props);
  }

  displayAmount = () => {
    const { crypto_amount } = this.props.transactionData;
    return <span style={{ fontSize: '10px' }}>Requested amount : {crypto_amount}</span>
  }

  validateInput = (rule, value, callback) => {
    var regexp = /^\d+(\.\d{1,4})?$/;
    if (value && !regexp.test(value)) {
      callback('Please enter a valid number with 4 decimal places');
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { onStatusChange, isAmountRequired, transactionData: { transaction_id, ext_ref_id }, onInputNumberChange } = this.props;

    return (
      <div>
        <Form layout="horizontal" ref={this.wrappedComponentRef}>
          <Form.Item>
            <Descriptions layout="horizontal" column={1} size="small" bordered>
              <Descriptions.Item label="Transaction No">{transaction_id}</Descriptions.Item>
              <Descriptions.Item label="Ext. Reference No">{ext_ref_id}</Descriptions.Item>
            </Descriptions>
          </Form.Item>
          <Form.Item label={`Transaction Status`}>
            {getFieldDecorator('status', {
              rules: [
                {
                  required: true,
                  message: `Please select a transaction status`,
                },
              ], initialValue: '' })(
              <Select onChange={onStatusChange}>
                <Option value="Completed" key={0}>Complete</Option>
                <Option value="Rejected" key={1}>Reject</Option>
                <Option value="Mismatch" key={2}>Mismatch</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label={`Received amount`} extra={this.displayAmount()}>
            {getFieldDecorator('received_amount', {
              rules: [
                {
                  required: isAmountRequired,
                  message: `Please enter a valid amount`,
                },
                {
                  validator: this.validateInput,
                }
              ]
            })(
              <InputNumber min={0} disabled={!isAmountRequired} onChange={onInputNumberChange} style={{ width: 'max-content'}} />
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
}
TransactionUpdateForm = Form.create({ name: "transaction_update" })(TransactionUpdateForm);

export default TransactionUpdateForm;