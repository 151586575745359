import * as containers from './containers';
import * as components from './components';
import reducer from "./ducks";
import * as actions from "./ducks";
import * as constants from "./constants";

export default {
  containers,
  components,
  reducer,
  actions,
  constants
}