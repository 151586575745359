import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { asyncActionMessages, unauthorizedAlert } from 'modules/core/middlewares';

import userProfile from '../modules/userProfile';
import topup from '../modules/topup';
import cashout from '../modules/cashout';
import wallet from '../modules/wallet';
import trade from '../modules/trade';
import withdraw from '../modules/withdraw';
import external from '../modules/external';
import limitorder from '../modules/limitorder';
import deposit from '../modules/deposit';
import transfer from '../modules/transfer';
import webhook from '../modules/webhook';
import summary from '../modules/summary';

const reducer = combineReducers({
    userProfile: userProfile.reducer,
    topup: topup.reducer,
    cashout: cashout.reducer,
    wallet: wallet.reducer,
    trade: trade.reducer,
    withdraw: withdraw.reducer,
    external: external.reducer,
    limitorder: limitorder.reducer,
    deposit: deposit.reducer,
    transfer: transfer.reducer,
    webhook: webhook.reducer,
    summary: summary.reducer,
});

const middlewares = [thunk, unauthorizedAlert, asyncActionMessages];

export default function initializeStore(preloadedState) {
    return createStore(reducer, preloadedState, applyMiddleware(...middlewares));
}
