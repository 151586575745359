import React, { Component } from "react";
import { connect } from "react-redux";

import { changePassword } from "../ducks";
import { ChangePwdForm } from "../components";

class ChangePwdFormContainer extends Component {
  constructor(props) {
    super(props);

    this.form = [];
  }

  handleChangePwd = () => {
    const { changePassword } = this.props;
    this.form.props.form.validateFields((err, values) => {
      if (!err) {
        changePassword({
          ...values,
          msgSuccess: "Password changed successfully"
        }).then(response => {
          if (response && response.status === 200) {
            this.onClose();
          }
        });
      }
    });
  };

  onClose = () => {
    this.form.props.form.resetFields();
  };

  render() {
    const { loading } = this.props;

    return (
      <ChangePwdForm
          wrappedComponentRef={form => {
            this.form = form;
          }}
          loading={loading}
          handleChangePwd={this.handleChangePwd}
          onClose={this.onClose}
        />
    );
  }
}

const mapStateToProps = ({ userProfile: { loading } }) => ({ loading });

export default connect(
  mapStateToProps,
  { changePassword }
)(ChangePwdFormContainer);
