import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col } from "antd";

const StyledForm = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0px;
`;

const StyledBackground = styled.div`
  height: 100%;
  background-image: url("/static/img/login_bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
`;

const StyledTitle = styled.div`
  position: absolute;
  bottom: 24px;
  left: 48px;
  color: #fff;
  text-shadow: 2px 2px 0px #292929;

  .title {
    font-size: 3.5rem;
    margin-bottom: 80px;
    position: relative;
    color: #fff;

    &::before {
      position: absolute;
      bottom: -24px;
      content: "";
      left: 0;
      width: 35%;
      height: 3px;
      background: ${props => props.theme.primaryColor};
      box-shadow: #fff 0px 1px 0px 0px;
    }
  }
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 72px;

  img {
    width: 40px;
    margin: 0 8px 0 24px;
  }

  .title {
    margin-bottom: 0;
  }
`;

class LoginPageLayout extends Component {
  render() {
    return (
      <Row type="flex" style={{ height: "100vh" }}>
        <Col xs={0} md={12}>
          <StyledBackground>
            <StyledTitle>
              <p className="title">{process.env.APP_NAME}</p>

              <p>{process.env.APP_NAME} © 2020</p>
            </StyledTitle>
          </StyledBackground>
        </Col>
        <Col xs={24} md={12} style={{ padding: "24px" }}>
          <StyledForm>
            <div>
              <StyledLogo>
                <span className="title is-3">{process.env.APP_NAME}</span>
              </StyledLogo>
              {this.props.children}
            </div>
          </StyledForm>
        </Col>
      </Row>
    );
  }
}

export default LoginPageLayout;
