import React, { useEffect } from 'react'
import { WalletGroupList } from '../components'
import { webhookAllowedSuperAdminWallets } from '../../core/config'
import { connect } from "react-redux"

const WalletGroupContainer = ({ currentUser }) => {
  if (!currentUser || !webhookAllowedSuperAdminWallets.includes(currentUser.wallet.id)) {
    return (
      <div>You don't have permission to access this page</div>
    )
  }
  return (
    <WalletGroupList />
  )
}

const mapStateToProps = ({ userProfile: { currentUser } }) => ({ currentUser })

export default connect(mapStateToProps)(WalletGroupContainer)