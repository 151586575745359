import React, { Component } from "react";
import { connect } from "react-redux";

import { createAccount } from "../ducks/list";
import { CreateAccountForm } from "../components";
import { Row, Col, Divider, Button } from "antd";

class CreateAccountFormContainer extends Component {
  constructor(props) {
    super(props);

    this.form = [];
  }

  handleCreateAccount = () => {
    const { createAccount, handleFetchData } = this.props;
    this.form.props.form.validateFields((err, values) => {
      if (!err) {
        createAccount({
          ...values,
          msgSuccess: "Account created successfully"
        }).then(response => {
          if (response && response.status === 200) {
            this.onClose();
            handleFetchData();
          }
        });
      }
    });
  };

  onClose = () => {
    this.form.props.form.resetFields();
    this.props.onClose();
  };

  render() {
    const { creating } = this.props;

    return (
      <div>
        <h5 className="subtitle">Create Account</h5>
        <Divider />
        <CreateAccountForm
          wrappedComponentRef={form => {
            this.form = form;
          }}
        />
        <Divider />
        <Row type="flex" align="middle" justify="center">
          <Col xs={24} md={24}>
            <div className="is-clearfix">
              <Button
                type="primary"
                onClick={this.handleCreateAccount}
                className="is-pulled-right"
                loading={creating}
              >
                Create Account
              </Button>
              <Button
                type="default"
                onClick={this.onClose}
                disabled={creating}
                className="is-pulled-right margin-right-5"
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  wallet: {
    list: { creating }
  }
}) => ({
  creating
});

export default connect(
  mapStateToProps,
  { createAccount }
)(CreateAccountFormContainer);
