import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { PageHeader, Row } from 'antd';

import { WithdrawTransactionList } from 'modules/withdraw/components';
import { EmptyTransactionList, AdvancedFilter } from 'modules/core/components';
import { fetchWithdrawTransactions, exportTransactions } from '../ducks/list';

class WithdrawTransactionListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1
    };
  }

  componentDidMount() {
    const { page } = this.state;
    const data = {
        page
    };
    this.fetchTransactions(data);
  }

  handleFetchData = page => {
    this.setState({ page: page });
    this.handleFetchTransactions(page);
  };

  fetchTransactions = (data) => {
    const { fetchWithdrawTransactions } = this.props;
    const withdrawData = {
        ...data,
        type: 'Withdraw'
    }
    fetchWithdrawTransactions(withdrawData);
  };

  onFilterClick = () => {
    this.handleFetchTransactions(1);
  };

  handleFetchTransactions = (page) => {
    this.form.props.form.validateFieldsAndScroll((err, formData) => {
        if (!err) {
            const data = {
              page,
              ...formData,
              fromDate: (formData.fromDate !== undefined && formData.fromDate !== null) ? formData.fromDate.format("YYYY-MM-DD") : null,
              toDate: (formData.toDate !== undefined && formData.toDate !== null) ? formData.toDate.format("YYYY-MM-DD") : null
            };
  
          this.fetchTransactions(data);
        }
      });
    }

  onExportClick = () => {
    this.form.props.form.validateFieldsAndScroll((err, formData) => {
      if (!err) {
        const data = {
            ...formData,
            fromDate: (formData.fromDate !== undefined && formData.fromDate !== null) ? formData.fromDate.format("YYYY-MM-DD") : null,
            toDate: (formData.toDate !== undefined && formData.toDate !== null) ? formData.toDate.format("YYYY-MM-DD") : null,
            type: 'withdraw'
        };

        this.props.exportTransactions(data);
      }
    });
  };

  onClear = () => {
    this.form.props.form.resetFields();
    const data = {
      page: 1,
    };
    this.fetchTransactions(data);
  }

  render() {
    const {
      list: { data, pagination, loading, showAmountSent }
    } = this.props;

    return (
        <div>
            <Row>
                <PageHeader title="Transactions - Withdrawals" />
            </Row>
            <br />
            <Row>
                <AdvancedFilter
                    wrappedComponentRef={form => {
                    this.form = form;
                    }}
                    onFilterClick={this.onFilterClick}
                    fromDate={this.state.fromDate}
                    onExportClick={this.onExportClick}
                    loading={loading}
                    exportable
                    statusList={['Submitted', 'Completed']}
                    onClear={this.onClear}
                    type='Withdraw'
                    userWalletGroupId={this.props.currentUser && this.props.currentUser.wallet.wallet_group_id}
                />
            </Row>
            <br />
            <Row>
                {isEmpty(data) ? <EmptyTransactionList /> :
                    <WithdrawTransactionList
                        data={data}
                        pagination={pagination}
                        handleFetchData={this.handleFetchData}
                        showAmountSent={showAmountSent}
                        loading={loading}
                    />}
            </Row>
        </div>
    )
  }
}

const mapStateToProps = ({ withdraw: { list }, userProfile: { currentUser } }) => ({ list, currentUser });

export default connect(mapStateToProps, { fetchWithdrawTransactions, exportTransactions })(WithdrawTransactionListContainer);
