import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { PageHeader, Row } from 'antd';

import { TransferTransactionList } from 'modules/transfer/components';
import { EmptyTransactionList, AdvancedFilter } from 'modules/core/components';
import { fetchTransferTransactions, exportTransactions } from '../ducks/list';

class TransferTransactionListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
  }

  componentDidMount() {
    const { page } = this.state;
    const data = {
        page
    };
    this.fetchTransactions(data);
  }

  handleFetchData = page => {
    this.setState({ page: page });
    this.handleFetchTransactions(page);
  };

  fetchTransactions = (data) => {
    const { fetchTransferTransactions } = this.props;
    const transferData = {
        ...data,
        type: 'Transfer'
    }
    fetchTransferTransactions(transferData);
  };

  onFilterClick = () => {
    this.handleFetchTransactions(1);
  };

  handleFetchTransactions = (page) => {
    this.form.props.form.validateFieldsAndScroll((err, formData) => {
        if (!err) {
            const data = {
              page,
              ...formData,
              fromDate: (formData.fromDate !== undefined && formData.fromDate !== null) ? formData.fromDate.format("YYYY-MM-DD") : null,
              toDate: (formData.toDate !== undefined && formData.toDate !== null) ? formData.toDate.format("YYYY-MM-DD") : null
            };
  
          this.fetchTransactions(data);
        }
      });
  }
  
  onExportClick = () => {
    this.form.props.form.validateFieldsAndScroll((err, formData) => {
      if (!err) {
        const data = {
            ...formData,
            fromDate: (formData.fromDate !== undefined && formData.fromDate !== null) ? formData.fromDate.format("YYYY-MM-DD") : null,
            toDate: (formData.toDate !== undefined && formData.toDate !== null) ? formData.toDate.format("YYYY-MM-DD") : null,
            type: 'transfer'
        };

        this.props.exportTransactions(data);
      }
    });
  };

  onClear = () => {
    this.form.props.form.resetFields();
    const data = {
      page: 1,
    };
    this.fetchTransactions(data);
  }

  render() {
    const {
      list: { data, pagination, loading }
    } = this.props;

    return (

      <div>
        <Row>
            <PageHeader title="Transactions - Transfers" />
        </Row>
        <br />
        <Row style={{ marginBottom: 10 }}>
            <AdvancedFilter
                wrappedComponentRef={form => {
                this.form = form;
                }}
                onFilterClick={this.onFilterClick}
                fromDate={this.state.fromDate}
                onExportClick={this.onExportClick}
                loading={loading}
                exportable
                statusList={['Submitted', 'Completed']}
                onClear={this.onClear}
            />
        </Row>
        <br />
        <Row>
            {isEmpty(data) ? <EmptyTransactionList /> :
            <TransferTransactionList
                data={data}
                pagination={pagination}
                handleFetchData={this.handleFetchData}
                loading={loading}
            />}
        </Row>
      </div>
    )
  }
}

const mapStateToProps = ({ transfer: { list } }) => ({ list });

export default connect(mapStateToProps, { fetchTransferTransactions, exportTransactions })(TransferTransactionListContainer);
