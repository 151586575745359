import React, { Component } from 'react';
import Router from 'next/router';
import { connect } from 'react-redux';
import jsCookie from 'js-cookie';

import { loginByEmail, requestResetPassword } from '../ducks';
import { COOKIE_EXPIRES } from '../constants';
import { LoginForm, ResetPasswordForm } from '../components';

class LoginFormContainer extends Component {

    state = {
        loading: false,
        isLoginFail: false,
        isLoginForm: true,
    }

    handleLoginSubmit = e => {
        e.preventDefault();
        this.setState({ loading: true, isLoginFail: false });
        const { loginByEmail } = this.props;

        this.loginForm.props.form.validateFields((err, values) => {
            if (!err) {
                loginByEmail({ ...values, app: "admin" }).then(res => {
                    this.setState({ loading: false });
                    if (res.status !== 401) {
                        this.setState({ isLoginFail: false });
                        jsCookie.set("accessToken", res.data.data.access_token, { expires: COOKIE_EXPIRES });
                        const page = Router.router.query.redirectUrl || '/wallets';
                        Router.push(page);
                    } else {
                        this.setState({ isLoginFail: true });
                    }
                });
            }
        });
    };

    handleShowLoginForm = e => {
        e.preventDefault();
        this.setState({ isLoginForm: !this.state.isLoginForm, loading: false });
    };

    handleResetPassword = e => {
        const { requestResetPassword } = this.props;
        e.preventDefault();
        this.setState({ loading: true });
        this.resetPasswordForm.props.form.validateFields((err, values) => {
            if (!err) {
                const dataSubmit = { data: { email: values.email }, msgSuccess: 'Sent reset password instruction!' };
                requestResetPassword(dataSubmit);
                this.setState({ loading: false });
            }
        });
    };

    render() {
        const { loading, isLoginFail, isLoginForm } = this.state;

        return (
            isLoginForm ? (
                <LoginForm
                    loading={loading}
                    isLoginFail={isLoginFail}
                    wrappedComponentRef={form => {
                        this.loginForm = form;
                    }}
                    handleLoginSubmit={this.handleLoginSubmit}
                    onClickForgotPassword={this.handleShowLoginForm}
                />
            ) : (
                    <ResetPasswordForm
                        loading={loading}
                        wrappedComponentRef={form => {
                            this.resetPasswordForm = form;
                        }}
                        onClickBackToLogin={this.handleShowLoginForm}
                        handleResetPassword={this.handleResetPassword}
                    />
                )
        )
    }
}

export default connect(null, { loginByEmail, requestResetPassword })(LoginFormContainer)
