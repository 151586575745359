import React, { useState, useEffect } from 'react'
import {Row, Col, Card, Form, Input, Button, Tag, notification, PageHeader, Modal} from 'antd'
import MultipleInut from './MultipleInput'

const initState = {
  depositUrls: [],
  payUrls: [],
  withdrawalUrls: [],
  isVerifyOtp: false,
  showModalVerifyOtp: false,
  otpValue: ''
}

const Settings = ({ loading, form, changeSetting, getSetting, currentUser, verifyOtp }) => {
  const [state, setCurrentState] = useState(initState)

  const setState = (newState) => {
    setCurrentState((currentState) => ({ ...currentState, ...newState }))
  }

  const { getFieldDecorator } = form

  const validateUrls = (urls = []) => {
    let httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
    let isUrlValid = true

    urls.forEach((url) => {
      if (url && !httpRegex.test(url)) {
        isUrlValid = false
      }
    })

    return isUrlValid
  }
  const openModalVerifyOtp = () => {
    setState({
      showModalVerifyOtp: true,
      otpValue: ''
    });
  };

  const hideModalVerifyOtp = () => {
    setState({
      showModalVerifyOtp: false,
      otpValue: ''
    });
  };

  const onChangeOtp = (value) => {
    setState({otpValue: value})
  }

  const submitVerifyOtp = () => {
    verifyOtp({
      otp: state.otpValue,
      verify_route_name: 'users.change-setting',
      msgSuccess: "Verified 2FA OTP successfully!"
    }).then(async res => {
      if (res.status === 200) {
        await hideModalVerifyOtp()
        await setState({isVerifyOtp: false})
        handleChangeSetting(false)
      }
    });
  }

  const handleChangeSetting = (isVerifyOtp = true) => {
    if (!validateUrls(state.depositUrls)) {
      notification.error({
        message: 'Your Deposit urls are not valid'
      })
      return
    }

    if (!validateUrls(state.payUrls)) {
      notification.error({
        message: 'Your Pay urls are not valid'
      })
      return
    }

    if (!validateUrls(state.withdrawalUrls)) {
      notification.error({
        message: 'Your Withdraw urls are not valid'
      })
      return
    }

    if (isVerifyOtp){
      openModalVerifyOtp()
    }else{
      changeSetting({
        settings: state,
        msgSuccess: "Settings are changed successfully"
      })
    }
  }

  const onClose = () => {
    form.resetFields()
  }

  useEffect(() => {
    getSetting({}).then(response => {
      const { data } = response.data
      if (data.length) {
        data.map((item) => {
          if (item.config_name == 'DEPOSIT_TRANSACTION_UPDATE_WEBHOOK_URL') {
            setState({ depositUrls: item.config_value })
          }
          if (item.config_name == 'PAY_TRANSACTION_UPDATE_WEBHOOK_URL') {
            setState({ payUrls: item.config_value })
          }
          if (item.config_name == 'WITHDRAW_TRANSACTION_UPDATE_WEBHOOK_URL') {
            setState({ withdrawalUrls: item.config_value })
          }
          setState({isVerifyOtp: currentUser.is_enable_google2fa})
        })
      }
    })
  }, [])

  return (
    <Row>
      <Row>
        <PageHeader title="Settings - Callback Urls" />
      </Row>
      <Col xs={24} md={12}>
        <Card style={{ marginTop: '1em', paddingRight: '1em' }}>
          <Form
            layout="vertical"
            labelAlign="left"
          >
            <Modal
                title="Verify OTP"
                visible={state.showModalVerifyOtp}
                onOk={submitVerifyOtp}
                onCancel={hideModalVerifyOtp}
                okText="Verify"
                cancelText="Cancel"
            >
              <Row>
                <Col xs={6} md={6}>OTP</Col>
                <Col xs={18} md={18}><Input value={state.otpValue} placeholder="Enter OTP" onChange={(e) => {onChangeOtp(e.target.value)}} /></Col>
              </Row>
            </Modal>

            <div style={{ marginTop: '24px', marginLeft: '24px' }}>
              <Form.Item label="Deposit">
                <MultipleInut value={state.depositUrls} onChange={(depositUrls) => setState({ depositUrls })} />
              </Form.Item>

              <Form.Item label="Pay">
                <MultipleInut value={state.payUrls} onChange={(payUrls) => setState({ payUrls })} />
              </Form.Item>

              <Form.Item label="Withdrawal">
                <MultipleInut value={state.withdrawalUrls} onChange={(withdrawalUrls) => setState({ withdrawalUrls })} />
              </Form.Item>

              <Row type="flex" align="middle" justify="center">
                <Col xs={24} md={24}>
                  <div className="is-clearfix">
                    <Button
                      type="primary"
                      onClick={handleChangeSetting}
                      className="is-pulled-right"
                      loading={loading}
                    >
                      Save
                    </Button>
                    <Button
                      type="default"
                      onClick={onClose}
                      disabled={loading}
                      className="is-pulled-right margin-right-5"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

const SettingForm = Form.create({ name: "setting_form" })(Settings)

export default SettingForm