import { combineReducers } from 'redux';

import reducerList, * as actionsList from './webhook';

const reducer = combineReducers({
    webhook: reducerList
});

export const actions = {
    webhook: actionsList,
};

export default reducer;
