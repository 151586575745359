import React, { Component } from "react"
import { connect } from "react-redux"

import {changeSetting, getSetting, verifyOtp} from "../ducks"
import { SettingForm } from "../components"

const SettingFormContainer = ({ loading, changeSetting, getSetting, currentUser, verifyOtp }) => {
  return (
    <SettingForm
      loading={loading}
      changeSetting={changeSetting}
      getSetting={getSetting}
      currentUser={currentUser}
      verifyOtp={verifyOtp}
    />
  )
}

const mapStateToProps = ({ userProfile: { loading, currentUser } }) => ({ loading, currentUser })

export default connect(
  mapStateToProps,
  { changeSetting, getSetting, verifyOtp }
)(SettingFormContainer)
