import dotProp, { set } from 'dot-prop-immutable-chain';
import { handleActions } from 'redux-actions';
import axios from 'axios';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { asyncActions } from 'modules/core/utils';
import { API_URL } from '../constants';

const INITIAL_STATE = {
  data: [],
  pagination: null,
  loading: false,
  error: null,
  walletList: [],
  address: '',
  loadingAddress: false
};

// ------------------------------------------- ACTIONS -------------------------------------------
export const fetchWalletList = asyncActions('TRANSACTIONSUMMARY/FETCH_WALLETS', ({ wallet_group_id }) => axios.get(`${API_URL}/wallet/group/${wallet_group_id}/addresses`));

export const fetchAddressByWalletId = asyncActions('TRANSACTIONSUMMARY/GET_ADDRESS_BY_WALLET_ID', ({ walletId, currencyId }) => {
    return axios.get(`${API_URL}/wallet/${walletId}/address/${currencyId}`);
});

export const fetchTransactionsSummary = asyncActions('TRANSACTIONSUMMARY/FETCH_TRANSACTIONS_SUMMARY', (data) => {
  return axios.get(`${API_URL}/transaction/ledger`, { params: data });
});

export const exportTransactionsSummary = asyncActions('TRANSACTIONSUMMARY/EXPORT_TRANSACTIONS_SUMMARY', (data) => {
    return axios.get(`${API_URL}/transaction/ledger/export`, { params: data, responseType: 'blob'});
  });

// ------------------------------------------- REDUCER -------------------------------------------
export default handleActions(
  {
    [fetchWalletList.START]: state => 
        dotProp(state)
            .set('loading', true)
            .set('data', [])
            .value(),

    [fetchWalletList.SUCCESS]: (state, { payload }) =>
        dotProp(state)
            .set('walletList', payload.data)
            .set('loading', false)
            .set('error', null)
            .value(),

    [fetchWalletList.FAILURE]: (state, { payload }) =>
        dotProp(state)
            .set('loading', false)
            .set('error', true)
            .value(),

    [fetchAddressByWalletId.START]: (state, { payload }) =>
        dotProp(state)
            .set('loadingAddress', true)
            .set('address', '')
            .value(),

    [fetchAddressByWalletId.SUCCESS]: (state, { payload }) =>
        dotProp(state)
            .set('address', payload.data)
            .set('loadingAddress', false)
            .set('error', null)
            .value(),

    [fetchAddressByWalletId.FAILURE]: (state, { payload }) =>
        dotProp(state)
            .set('address', '')
            .set('loadingAddress', false)
            .set('error', true)
            .value(),

    [fetchTransactionsSummary.START]: state => 
        dotProp(state)
            .set('loading', true)
            .set('data', [])
            .value(),

    [fetchTransactionsSummary.SUCCESS]: (state, { payload }) => {
      const {
        data,
        meta: { pagination }
      } = payload.data;

      return dotProp(state)
        .set('data', data)
        .set('pagination', pagination)
        .set('loading', false)
        .set('error', null)
        .value();
    },

    [exportTransactionsSummary.START]: (state) => set(state, 'loading', true),

    [exportTransactionsSummary.SUCCESS]: (state, response) => {
      const { payload: { data } } = response;
      let fileName = `Ledger Transactions Export - ${moment().format('L LT')}`;
      saveAs(data, fileName);

      return dotProp(state)
        .set("loading", false)
        .value();
    },
  },
  INITIAL_STATE
);
