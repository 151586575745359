import dotProp, { set } from "dot-prop-immutable-chain";
import { handleActions, createAction } from "redux-actions";
import axios from "axios";
import { asyncActions } from "modules/core/utils";
import { API_URL } from "../constants";

const INITIAL_STATE = {
  loading: false,
  data: null,
  error: null,
  errorMsg: "",
  success: false
};
// ------------------------------------------- ACTIONS -------------------------------------------

export const updateExternal = asyncActions(
  "TRANSACTION/UPDATE_EXTERNAL_TRANSACTON",
  ({ data }) => {
    return axios.put(`${API_URL}/external-topups/${data.id}`, data);
  }
);

export const clearUpdateExternal = createAction(
  "TRANSACTION/CLEAR_UPDATE_EXTERNAL_TRANSACTON"
);

// ------------------------------------------- REDUCER -------------------------------------------
export default handleActions(
  {
    [updateExternal.START]: state => set(state, "loading", true),

    [updateExternal.SUCCESS]: (state, { payload }) =>
      dotProp(state)
        .set("loading", false)
        .set("data", payload)
        .set("error", false)
        .set("errorMsg", "")
        .set("success", true)
        .value(),

    [updateExternal.FAILURE]: (state, { payload }) =>
      dotProp(state)
        .set("loading", false)
        .set("data", null)
        .set("error", true)
        .set("errorMsg", payload.data && payload.data.errors)
        .set("success", false)
        .value(),

    [clearUpdateExternal]: (state, { payload }) =>
      dotProp(state)
        .set("loading", false)
        .set("data", null)
        .set("error", null)
        .set("errorMsg", null)
        .set("success", false)
        .value(),
  },
  INITIAL_STATE
);
