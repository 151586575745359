import React from 'react';
import { Card, Empty } from 'antd';

const EmptyTransactionList = () =>
  <Card>
    <Empty
      description={
        <span>
          No transactions found
        </span>
      }
    >
    </Empty>
  </Card>

export default EmptyTransactionList;
