import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Table, Typography, Tag, Button, Tooltip, Icon } from 'antd';

import { getStatusTagColor } from '../../core/utils';

const { Text } = Typography;
const StyledTable = styled(Table)`
    .ant-table-placeholder{
        border-bottom: 0px;
    }
`;

const StyledTag = styled(Tag)`
    text-align: center;
    width: 108px;
    margin-top: 2px;
`;

class LimitOrdersList extends Component {
  constructor(props) {
    super(props);
    this.limitOrders = this.props.data;
    this.columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text, data) => (
          <span>
            <Text>{moment.utc(data.date).local().format('YYYY-MM-DD')}</Text>
            <br />
            <Text type="secondary">{moment.utc(data.date).local().format('hh:mm a')}</Text>
          </span>
        )
      },
      {
        title: 'Expire On',
        dataIndex: 'expire_on',
        key: 'expire_on',
        render: (text, data) => (
          <span>
            <Text>{moment.utc(data.expire_on).local().format('YYYY-MM-DD')}</Text>
            <br />
            <Text type="secondary">{moment.utc(data.expire_on).local().format('hh:mm a')}</Text>
          </span>
        )
      },
      {
        title: 'Transaction No',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: "Wallet/Alias",
        dataIndex: 'wallet_alias',
        key: 'walletAlias',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Pair',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'amount',
        align: 'right'
      },
      {
        title: 'Price',
        key: 'price',
        dataIndex: 'price',
        align: 'right'
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 125,
        render: status => (
          <StyledTag color={getStatusTagColor(status)}>
            {status == 'PendingExecute' ? 'PENDING' : status.toUpperCase()}
          </StyledTag>
        )
      },
      {
        title: "Action",
        dataIndex: 'action',
        key: 'action',
        width: 50,
        render: (text, record) => (
          record.status == 'Submitted' && !record.cancellation_requested && this.renderActionButtons(record.id)
        )
      },
    ];
  }

  renderActionButtons = id => (
    <Fragment>
      <Tooltip title="Cancel Order">
        <Button shape="circle" onClick={() => this.props.onCancel(id)}>
          <Icon type="close" />
        </Button>
      </Tooltip>
    </Fragment>
  );

  render() {
    return (
      <StyledTable
        bordered={true}
        columns={this.columns}
        dataSource={this.limitOrders}
        rowKey="id"
        rowClassName="table"
        pagination={{
          onChange: page => {
            this.props.handleFetchData(page);
          },
          current: this.props.pagination.current_page,
          total: this.props.pagination.total,
          pageSize: this.props.pagination.per_page,
        }}
      />
    );
  }
}

export default LimitOrdersList;
