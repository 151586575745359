import dotProp, { set } from "dot-prop-immutable-chain"
import { handleActions, createAction } from "redux-actions"
import axios from "axios"
import { asyncActions } from "modules/core/utils"
import { API_URL } from "../constants"

const INITIAL_STATE = {
  data: [],
  pagination: null,
  loading: false,
  error: null,
  creating: false,
  submitting: false,
  sendSuccess: false,
  sendError: null,
  balancesLoading: false,
  balances: null,
  feeData: null,
  feeLoading: false,
  feeSuccess: null,
  feeError: null,
  masterWalletDataLoading: false,
  masterWalletData: null,
  currencyList: [],
  showAdvanceFee: false,
  destCryptoAddressLoading: false,
  destCryptoAddress: ''
}

// ------------------------------------------- ACTIONS -------------------------------------------
export const updateWalletGroup = asyncActions("WALLET/UPDATE_WALLET_GROUP", data =>
  axios.post(`${API_URL}/wallet-group`, { ...data })
)

export const fetchWalletGroups = asyncActions("WALLET/FETCH_WALLET_GROUPS", data =>
  axios.get(`${API_URL}/wallet-group`)
)

export const fetchAllCurrencies = asyncActions("WALLET/FETCH_ALL_CURRENCY", data =>
  axios.get(`${API_URL}/currency`)
)

export const fetchWallets = asyncActions("WALLET/FETCH_WALLETS", data =>
  axios.get(`${API_URL}/wallet`, { params: data })
)

export const createAccount = asyncActions("WALLET/CREATE_ACCOUNT", data =>
  axios.post(`${API_URL}/admin-signup`, { ...data })
)

export const withdrawCrypto = asyncActions("WALLET/SEND_USDT", data =>
  axios.post(`${API_URL}/transaction/admin-withdraw-crypto`, { ...data })
)

export const verifyOtp = asyncActions("WALLET/VERIFY_OTP", data =>
    axios.post(`${API_URL}/google2fa/verify`, { ...data })
);

export const getBalances = asyncActions('WALLET/GET_BALANCES', id =>
  axios.get(`${API_URL}/wallet/${id}/balances`)
)

export const getFee = asyncActions('WALLET/GET_FEE', data =>
  axios.post(`${API_URL}/transaction/withdraw-fee`, { ...data })
)

export const fetchMasterWallet = asyncActions("WALLET/FETCH_MASTER_WALLET", currency =>
  axios.get(`${API_URL}/wallet/master/${currency}`)
)

export const clearFeeData = createAction('WALLET/CLEAR_FEE_DATA')

export const fetchCurrencies = asyncActions("WALLET/FETCH_CURRENCIES", () =>
  axios.get(`${API_URL}/wallet/currencies`)
)

export const transferCrypto = asyncActions("WALLET/TRANSFER_USDT", data =>
  axios.post(`${API_URL}/transaction/transfer`, { ...data })
)

export const fetchDestCryptoAddress = asyncActions("WALLET/FETCH_DEST_CRYPTO_ADDRESS", (currency) =>
  axios.get(`${API_URL}/wallet/get-destination-address/${currency}`)
)

// ------------------------------------------- REDUCER -------------------------------------------
export default handleActions(
  {
    [fetchAllCurrencies.START]: (state, { payload }) => set(state, "loading", true),

    [fetchAllCurrencies.SUCCESS]: (state, { payload }) => {
      const {
        data,
      } = payload.data

      return dotProp(state)
        .set("currencies", data)
        .value()
    },

    [updateWalletGroup.START]: (state, { payload }) => set(state, "loading", true),

    [updateWalletGroup.SUCCESS]: (state, { payload }) => {
      const {
        data,
      } = payload.data

      return dotProp(state)
        .value()
    },

    [fetchWalletGroups.START]: (state, { payload }) => set(state, "loading", true),

    [fetchWalletGroups.SUCCESS]: (state, { payload }) => {
      const {
        data,
      } = payload.data

      return dotProp(state)
        .set("groups", data)
        .set("loading", false)
        .set("error", null)
        .value()
    },

    [fetchWallets.START]: (state, { payload }) => set(state, "loading", true),

    [fetchWallets.SUCCESS]: (state, { payload }) => {
      const {
        data,
        meta: { pagination },
        show_advance_fee
      } = payload.data

      return dotProp(state)
        .set("data", data)
        .set("pagination", pagination)
        .set("showAdvanceFee", show_advance_fee)
        .set("loading", false)
        .set("error", null)
        .value()
    },

    [createAccount.START]: state => set(state, "creating", true),

    [createAccount.SUCCESS]: state =>
      dotProp(state)
        .set("creating", false)
        .value(),

    [createAccount.FAILURE]: state => set(state, "creating", false),

    [withdrawCrypto.START]: state => set(state, "submitting", true),

    [withdrawCrypto.SUCCESS]: state =>
      dotProp(state)
        .set("submitting", false)
        .set('sendSuccess', true)
        .value(),

    [withdrawCrypto.FAILURE]: (state, { payload }) =>
      dotProp(state)
        .set('submitting', false)
        .set('sendError', payload?.message)
        .set('sendSuccess', null)
        .value(),

    [getBalances.START]: state => set(state, 'balancesLoading', true),

    [getBalances.SUCCESS]: (state, { payload }) =>
      dotProp(state)
        .set('balances', payload.data)
        .set('balancesLoading', false)
        .set('error', null)
        .value(),

    [getBalances.FAILURE]: (state, { payload }) =>
      dotProp(state)
        .set('balancesLoading', false)
        .set('error', true)
        .value(),

    [getFee.START]: state =>
      dotProp(state)
        .set('feeLoading', true)
        .set('feeData', null)
        .value(),

    [getFee.SUCCESS]: (state, { payload }) =>
      dotProp(state)
        .set('feeData', payload?.data)
        .set('feeLoading', false)
        .set('feeError', null)
        .set('feeSuccess', true)
        .value(),

    [getFee.FAILURE]: (state, { payload }) =>
      dotProp(state)
        .set('feeLoading', false)
        .set('feeError', payload?.data?.message)
        .set('feeSuccess', null)
        .value(),

    [fetchMasterWallet.START]: state => set(state, "masterWalletDataLoading", true),

    [fetchMasterWallet.SUCCESS]: (state, { payload }) => {
      const { data } = payload

      return dotProp(state)
        .set("masterWalletData", data)
        .set("masterWalletDataLoading", false)
        .value()
    },

    [clearFeeData]: (state) =>
      dotProp(state)
        .set('feeLoading', false)
        .set('feeData', null)
        .set('feeError', null)
        .set('feeSuccess', null)
        .value(),

    [fetchCurrencies.START]: (state, { payload }) => set(state, "loading", true),

    [fetchCurrencies.SUCCESS]: (state, { payload }) => {
      const { data } = payload

      return dotProp(state)
        .set("currencyList", data)
        .set("loading", false)
        .set("error", null)
        .value()
    },

    [transferCrypto.START]: state => set(state, "submitting", true),

    [transferCrypto.SUCCESS]: state =>
      dotProp(state)
        .set("submitting", false)
        .set('sendSuccess', true)
        .value(),

    [transferCrypto.FAILURE]: (state, { payload }) =>
      dotProp(state)
        .set('submitting', false)
        .set('sendError', payload?.message)
        .set('sendSuccess', null)
        .value(),

    [fetchDestCryptoAddress.START]: state => set(state, "destCryptoAddressLoading", true),

    [fetchDestCryptoAddress.SUCCESS]: (state, { payload }) => {
      const { data } = payload

      return dotProp(state)
        .set("destCryptoAddress", data)
        .set("destCryptoAddressLoading", false)
        .value()
    },

    [fetchDestCryptoAddress.FAILURE]: (state) =>
      dotProp(state)
        .set('destCryptoAddressLoading', false)
        .set('destCryptoAddress', null)
        .value(),
  },
  INITIAL_STATE
)
