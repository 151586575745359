import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { Spin, PageHeader } from 'antd';

import { TradeTransactionList } from 'modules/trade/components';
import { EmptyTransactionList } from 'modules/core/components';
import { fetchTradeTransactions } from '../ducks/list';

class TradeTransactionListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
  }

  componentDidMount() {
    const { page } = this.state;
    const data = { page };
    this.props.fetchTradeTransactions(data);
  }

  handleFetchData = page => {
    this.setState({
      page: page
    });
    const data = { page };
    this.props.fetchTradeTransactions(data);
  };

  render() {
    const {
      list: { data, pagination, loading }
    } = this.props;

    if (loading) {
      return <Spin size="large" />;
    }

    return (

      <div>
        <PageHeader title="Transactions - Buy/Sell" />
        {isEmpty(data) ? <EmptyTransactionList /> :
          <TradeTransactionList
            data={data}
            pagination={pagination}
            handleFetchData={this.handleFetchData}
          />}
      </div>

    )
  }
}

const mapStateToProps = ({ trade: { list } }) => ({ list });

export default connect(mapStateToProps, { fetchTradeTransactions })(TradeTransactionListContainer);
