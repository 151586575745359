import React, { Fragment } from "react";
import styled from "styled-components";

import { Button, Alert, Form, Input } from "antd";

const StyledForm = styled(Form)`
  width: 400px;
  max-width: 100%;
  padding: 24px;
  background: #fff;
  border-radius: ${props => props.theme.borderRadiusBase};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;

  .forgot-password {
    float: right;
  }
`;

class LoginForm extends React.Component {
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isLoginFail,
      onClickForgotPassword,
      loading,
      form,
      handleLoginSubmit
    } = this.props;

    return (
      <StyledForm layout="vertical" onSubmit={handleLoginSubmit}>
        <p className="form-title title is-4">Glad to see you</p>
        <p className="subtitle is-5">Login to continue using {process.env.APP_NAME}</p>
        {isLoginFail && (
          <Alert
            style={{ marginBottom: "16px" }}
            message="Login failed! Wrong email or password."
            type="error"
            showIcon
          />
        )}
        <div>
          <Form.Item label="Username/Email">
            {getFieldDecorator("email", {})(<Input />)}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator("password", {})(<Input.Password />)}
          </Form.Item>
        </div>
        <div style={{ marginTop: "24px" }}>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Login
          </Button>
        </div>
        <div style={{ marginTop: "8px" }}>
          <span>Having trouble logging in?</span>
          <a href={`mailto:${process.env.CONTACT_EMAIL}`}> Contact us</a>
        </div>
      </StyledForm>
    );
  }
}

LoginForm = Form.create()(LoginForm);

export default LoginForm;
