import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Drawer, Card, Tabs } from "antd";
import { isEmpty } from "lodash";

import { WalletList, MasterWalletInfoPanel, BalanceInfoPanel } from "modules/wallet/components";
import { fetchWallets, getBalances, fetchMasterWallet, fetchCurrencies, fetchDestCryptoAddress } from "../ducks/list";
import CreateAccountFormContainer from "./CreateAccountFormContainer";
import SendFormContainer from "./SendFormContainer";
import { SearchForm } from "../components";
import { EmptyWalletList } from 'modules/core/components';

const { TabPane } = Tabs;

class WalletListContainer extends Component {
  constructor(props) {
    super(props);
    this.form = [];
    this.state = {
      page: 1,
      filter: '',
      showCreateAccModal: false,
      showSendModal: false,
      walletId: '',
      walletAddress: '',
      selectedTabCurrency: '',
      isTransfer: false,
      loadDefaultAddress: false
    };
  }

  componentDidMount() {
    const { fetchCurrencies } = this.props;
    fetchCurrencies();
  }

  componentDidUpdate(prevProps, prevState) {
    const { list } = this.props;

    if (!isEmpty(list.currencyList) && list.currencyList !== prevProps.list.currencyList) {
      this.onTabChange(list.currencyList[0]);
    }

    if (this.state.filter !== prevState.filter) {
      // No need to fetch master wallet information. Therefore overriding default behavior
      this.handleFetchData(0, false);
    }
  }

  handleFetchData = (page, fetchMaster = true) => {
    this.setState({ page: page });
    const { selectedTabCurrency, filter } = this.state;
    const data = { page, currency: selectedTabCurrency, filter };
    if (fetchMaster) {
      this.props.fetchMasterWallet(selectedTabCurrency);
    }
    this.props.fetchWallets(data);
  };

  onCreateClick = () => {
    this.setState({ showCreateAccModal: true });
  };

  onClose = () => {
    this.setState({ showCreateAccModal: false, showSendModal: false });
  };

  onSendClick = (id, address, isTransfer = false, loadDefaultAddress = false) => {;
    this.props.getBalances(id);
    if (loadDefaultAddress) {
        this.props.fetchDestCryptoAddress(this.state.selectedTabCurrency);
    }
    this.setState({ showSendModal: true, walletId: id, walletAddress: address, isTransfer: isTransfer, loadDefaultAddress: loadDefaultAddress });
  };

  onTabChange = (key) => {
    this.setState({ selectedTabCurrency: key }, () => this.handleFetchData());
  };

  onSearch = () => {
    const { form: { getFieldValue }} = this.form.props;
    let searchText = getFieldValue('search');
    this.setState({ filter: searchText })
  };

  onClearSearch = (e) => {
    if (e.target.value === '') {
      this.setState({ filter: null })
    }
  };

  render() {
    const {
      list: { data, pagination, loading, balances, balancesLoading, masterWalletData, masterWalletDataLoading, currencyList, showAdvanceFee, destCryptoAddressLoading, destCryptoAddress }
    } = this.props;

    const { showCreateAccModal, showSendModal, walletId, walletAddress, selectedTabCurrency, isTransfer, loadDefaultAddress } = this.state;

    const availableBalance = balances?.find(balance => balance.code == selectedTabCurrency)?.pivot?.available_balance;

    const createAccOperation = <Button key="add" type="primary" onClick={this.onCreateClick}>Create Account</Button>;

    return (
      <div>
        <Card>
          <SearchForm
            wrappedComponentRef={(form) => {
              this.form = form;
            }}
            onSearch={this.onSearch}
            onClearSearch={this.onClearSearch}
          ></SearchForm>
        </Card>
        <div>
          {!isEmpty(currencyList) && (
            <Card style={{ marginTop: "10px" }}>
              <Tabs
                onTabClick={this.onTabChange}
                tabBarExtraContent={createAccOperation}
              >
                {currencyList &&
                  currencyList.map((currency) => (
                    <TabPane tab={currency + " Wallets"} key={currency}>
                      {isEmpty(masterWalletData) ? (
                        ""
                      ) : masterWalletData.wallet_address ? (
                        <MasterWalletInfoPanel
                          data={masterWalletData}
                          loading={masterWalletDataLoading}
                          onSendClick={this.onSendClick}
                          currentUser={this.props.currentUser}
                        />
                      ) : (
                        <BalanceInfoPanel
                          data={masterWalletData}
                          loading={masterWalletDataLoading}
                        />
                      )}
                      <br />
                      {isEmpty(data) ? (<EmptyWalletList/>) : (
                        <WalletList
                          data={data}
                          pagination={pagination}
                          handleFetchData={this.handleFetchData}
                          loading={loading}
                          onClick={this.onSendClick}
                          currency={currency}
                        />
                      )}
                    </TabPane>
                  ))}
              </Tabs>
            </Card>
          )}
        </div>
        <Drawer visible={showCreateAccModal} width={320} closable={false}>
          <CreateAccountFormContainer
            onClose={this.onClose}
            handleFetchData={this.handleFetchData}
          />
        </Drawer>
        <Drawer visible={showSendModal} width={410} closable={false}>
          <SendFormContainer
            onClose={this.onClose}
            handleFetchData={this.handleFetchData}
            walletId={walletId}
            balance={availableBalance}
            balanceLoading={balancesLoading}
            walletAddress={walletAddress}
            selectedTabCurrency={selectedTabCurrency}
            showAdvanceFee={showAdvanceFee}
            masterWalletAddress={masterWalletData?.wallet_address}
            isTransfer={isTransfer}
            isFormVisible={showSendModal}
            destCryptoAddressLoading={destCryptoAddressLoading}
            destCryptoAddress={destCryptoAddress}
            loadDefaultAddress={loadDefaultAddress}
            currentUser={this.props.currentUser}
          />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({ wallet: { list }, userProfile: { currentUser } }) => ({ list, currentUser });

export default connect(
  mapStateToProps,
  { fetchWallets, getBalances, fetchMasterWallet, fetchCurrencies, fetchDestCryptoAddress }
)(WalletListContainer);
