import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { PageHeader, Row } from 'antd';

import { DepositTransactionList } from 'modules/deposit/components';
import { EmptyTransactionList, AdvancedFilter } from 'modules/core/components';
import { fetchDepositTransactions, exportTransactions } from '../ducks/list';

class DepositTransactionListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
  }

  componentDidMount() {
    const { page } = this.state;
    const data = {
        page
    };
    this.fetchTransactions(data);
  }

  handleFetchData = page => {
    this.setState({ page: page });
    this.handleFetchTransactions(page);
  };

  fetchTransactions = (data) => {
    const { fetchDepositTransactions } = this.props;
    const depositData = {
        ...data,
        type: 'Deposit'
    }
    fetchDepositTransactions(depositData);
  };

  onFilterClick = () => {
    this.handleFetchTransactions(1);
  };

  handleFetchTransactions = (page) => {
    this.form.props.form.validateFieldsAndScroll((err, formData) => {
        if (!err) {
            const data = {
              page,
              ...formData,
              fromDate: (formData.fromDate !== undefined && formData.fromDate !== null) ? formData.fromDate.format("YYYY-MM-DD") : null,
              toDate: (formData.toDate !== undefined && formData.toDate !== null) ? formData.toDate.format("YYYY-MM-DD") : null
            };
  
          this.fetchTransactions(data);
        }
      });
  }

  onExportClick = () => {
    this.form.props.form.validateFieldsAndScroll((err, formData) => {
      if (!err) {
        const data = {
            ...formData,
            fromDate: (formData.fromDate !== undefined && formData.fromDate !== null) ? formData.fromDate.format("YYYY-MM-DD") : null,
            toDate: (formData.toDate !== undefined && formData.toDate !== null) ? formData.toDate.format("YYYY-MM-DD") : null,
            type: 'deposit'
        };

        this.props.exportTransactions(data);
      }
    });
  };

  onClear = () => {
    this.form.props.form.resetFields();
    const data = {
      page: 1,
    };
    this.fetchTransactions(data);
  }
  
  render() {
    const {
      list: { data, pagination, loading }
    } = this.props;

    return (
      <div>
        <Row>
            <PageHeader title="Transactions - Deposits" />
        </Row>
        <br />
        <Row>
            <AdvancedFilter
                wrappedComponentRef={form => {
                this.form = form;
                }}
                onFilterClick={this.onFilterClick}
                fromDate={this.state.fromDate}
                onHashChange={this.onHashChange}
                onExportClick={this.onExportClick}
                loading={loading}
                exportable 
                statusList={['Pending', 'Completed']}
                onClear={this.onClear}
                userWalletGroupId={this.props.currentUser && this.props.currentUser.wallet.wallet_group_id}
            />
        </Row>
        <br />
        <div>
            {isEmpty(data) ? <EmptyTransactionList /> :
                <DepositTransactionList
                    data={data}
                    pagination={pagination}
                    handleFetchData={this.handleFetchData}
                    loading={loading}
                />
            }
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ deposit: { list }, userProfile: { currentUser } }) => ({ list, currentUser });

export default connect(mapStateToProps, { fetchDepositTransactions, exportTransactions })(DepositTransactionListContainer);
