import React, { Component } from "react"
import styled from "styled-components"
import Router from "next/router"
import { connect } from "react-redux"
import { Layout, Menu, Icon, Button } from "antd"
import Link from "next/link"
import { webhookAllowedGroups, webhookAllowedSuperAdminWallets } from "../config"

import { withLoginRequired } from "modules/core/hocs"
import { logout } from "../../userProfile/ducks"

const { Header, Content, Footer } = Layout
const { SubMenu } = Menu

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`

const StyledLogo = styled.div`
  float: left;
`

const StyledImg = styled.img`
  height: 30px;
`

const StyledContent = styled(Content)`
  margin: 32px 40px;
  padding: 24;
  background: #f5f5f6;
  minheight: 400px;
`

const StyledHeader = styled(Header)`
  padding: 0 40px;
`

const StyledMenuItem = styled(Menu.Item)`
  &.ant-menu-item-selected {
    background-color: unset !important;
  }
`

class PageLayout extends Component {
  constructor(props) {
    super(props)
  }

  onLogout = () => {
    logout()
    Router.push("/login")
  };

  profile = () => {
    Router.push("/profile/account");
  };

  onChangePwd = () => {
    Router.push("/change-password")
  };

  onSettings = () => {
    Router.push("/settings")
  };

  render() {

    const { currentUser } = this.props

    if (!currentUser) return null

    return (
      <StyledLayout>
        <StyledHeader>
          <StyledLogo className="logo">
            <StyledImg src="/static/img/logo.png" />
          </StyledLogo>
          <Menu theme="dark" mode="horizontal" style={{ lineHeight: "64px" }}>
            <SubMenu
              title={
                <span>
                  <Icon theme="outlined" type="transaction" />
                  <span>Transactions</span>
                </span>
              }
            >
              {/* <Menu.Item key="transactions-topups">
                <Link href={"/transactions/topups"} prefetch>
                  {
                    <span>
                      <Icon theme="outlined" type="arrow-up" />
                      <span>Top Ups</span>
                    </span>
                  }
                </Link>
              </Menu.Item>
              <Menu.Item key="transactions-cashouts">
                <Link href={"/transactions/cashouts"} prefetch>
                  {
                    <span>
                      <Icon theme="outlined" type="arrow-down" />
                      <span>Cash Outs</span>
                    </span>
                  }
                </Link>
              </Menu.Item> */}
              {/* <Menu.Item key="transactions-trades">
                <Link href={"/transactions/trades"} prefetch={false}>
                  {
                    <span>
                      <Icon theme='outlined' type="swap" />
                      <span>Buy/Sell</span>
                    </span>
                  }
                </Link>
              </Menu.Item> */}
              <Menu.Item key="transactions-deposits">
                <Link href={"/transactions/deposits"} prefetch={false}>
                  {
                    <span>
                      <Icon theme="outlined" type="arrow-up" />
                      <span>Deposits</span>
                    </span>
                  }
                </Link>
              </Menu.Item>
              <Menu.Item key="transactions-withdrawals">
                <Link href={"/transactions/withdrawals"} prefetch={false}>
                  {
                    <span>
                      <Icon theme="outlined" type="arrow-right" />
                      <span>Withdrawals</span>
                    </span>
                  }
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="transactions-limitorders">
                <Link href={"/transactions/limitorders"} prefetch={false}>
                  {
                    <span>
                      <Icon theme='outlined' type="dollar" />
                      <span>Limit Orders</span>
                    </span>
                  }
                </Link>
              </Menu.Item> */}
              <Menu.Item key="transactions-transfers">
                <Link href={"/transactions/transfers"} prefetch={false}>
                  {
                    <span>
                      <Icon theme="outlined" type="swap" />
                      <span>Transfers</span>
                    </span>
                  }
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              title={
                <span>
                  <Icon theme="outlined" type="export" />
                  <span>External</span>
                </span>
              }
            >
              {/* <Menu.Item key="external-topups">
                <Link href={"/external/topups"} prefetch={false}>
                  {
                    <span>
                      <Icon theme="outlined" type="arrow-up" />
                      <span>Top Ups</span>
                    </span>
                  }
                </Link>
              </Menu.Item> */}
              <Menu.Item key="external-pay">
                <Link href={"/external/pay"} prefetch={false}>
                  {
                    <span>
                      <Icon theme="outlined" type="arrow-down" />
                      <span>Pay</span>
                    </span>
                  }
                </Link>
              </Menu.Item>
            </SubMenu>
            {currentUser && webhookAllowedGroups.includes(currentUser.wallet.wallet_group_id) &&
              <Menu.Item key="tx-summary" onClick={event => Router.push("/tx-summary")}>
                {
                  <span>
                    <Icon theme="outlined" type="fund" />
                    <span>Transaction Summary</span>
                  </span>
                }
              </Menu.Item>
            }
            <Menu.Item key="wallets" onClick={event => Router.push("/wallets")}>
              {
                <span>
                  <Icon theme="outlined" type="wallet" />
                  <span>Wallet</span>
                </span>
              }
            </Menu.Item>
            {currentUser && (webhookAllowedSuperAdminWallets.includes(currentUser.wallet.id) || webhookAllowedGroups.includes(currentUser.wallet.wallet_group_id)) && (
              <StyledMenuItem key="webhook" onClick={event => Router.push("/webhook")}>
                {
                  <Button type="primary">
                    <span>
                      <Icon theme="outlined" type="deployment-unit" />
                      <span>Webhook Callback</span>
                    </span>
                  </Button>
                }
              </StyledMenuItem>
            )}

            {currentUser && (webhookAllowedSuperAdminWallets.includes(currentUser.wallet.id)) && (
              <StyledMenuItem key="update-transaction" onClick={event => Router.push("/transaction-update")}>
                {
                  <Button type="primary">
                    <span>
                      <Icon theme="outlined" type="deployment-unit" />
                      <span>Update Transaction Status and Fire Callback</span>
                    </span>
                  </Button>
                }
              </StyledMenuItem>
            )}

            <SubMenu
              title={
                <span>
                  <Icon theme="outlined" type="user" />
                  <span>{this.props.currentUser?.name}</span>
                </span>
              }
              style={{ float: "right" }}
            >
              <Menu.Item key="password" onClick={this.onChangePwd}>
                {
                  <span>
                    <Icon theme="outlined" type="key" />
                    <span>Change Password</span>
                  </span>
                }
              </Menu.Item>

              <Menu.Item key="profile" onClick={this.onLogout}>
                {
                  <span>
                    <Icon theme="outlined" type="logout" />
                    <span>Logout</span>
                  </span>
                }
              </Menu.Item>
            </SubMenu>

            <SubMenu
              style={{ float: "right" }}
              title={
                <span>
                  <Icon theme="outlined" type="setting" />
                  <span>Settings</span>
                </span>
              }
            >
              <Menu.Item key="settings" onClick={this.onSettings}>
                {
                  <span>
                    <Icon theme="outlined" type="arrow-left" />
                    <span>Callback Urls</span>
                  </span>
                }
              </Menu.Item>
              <Menu.Item key="profile" onClick={this.profile}>
                {
                  <span>
                    <Icon theme="outlined" type="profile" />
                    <span>Profile</span>
                  </span>
                }
              </Menu.Item>
              {currentUser && (webhookAllowedSuperAdminWallets.includes(currentUser.wallet.id)) && (
                <Menu.Item key="webhook" onClick={event => Router.push("/wallet-groups")}>
                  {
                    <span>
                      <Icon theme="outlined" type="import" />
                      <span>Wallet Groups</span>
                    </span>
                  }
                </Menu.Item>
              )}
            </SubMenu>
          </Menu>
        </StyledHeader>

        <StyledContent>{this.props.children}</StyledContent>

        <Footer style={{ textAlign: "center" }}>{process.env.APP_NAME} © 2020</Footer>
      </StyledLayout>
    )
  }
}

const mapStateToProps = ({ userProfile: { currentUser } }) => ({ currentUser })

export default withLoginRequired(
  connect(
    mapStateToProps,
    null
  )(PageLayout)
)
