import dotProp, { set } from 'dot-prop-immutable-chain';
import { handleActions } from 'redux-actions';
import axios from 'axios';
import { asyncActions } from 'modules/core/utils';
import { API_URL } from '../constants';

const INITIAL_STATE = {
    rejectData: null,
    errors: ''
};
// ------------------------------------------- ACTIONS -------------------------------------------

export const rejectWithdraw = asyncActions('TRANSACTION/REJECT_WITHDRAW', ({ id , isapproved}) =>{
  return axios.put(`${API_URL}/${id}`,{is_approved:isapproved});
});


// ------------------------------------------- REDUCER -------------------------------------------
export default handleActions(
    {
      [rejectWithdraw.START]: (state, { payload }) => set(state, 'loading', true),

      [rejectWithdraw.SUCCESS]: (state, { payload }) =>
                dotProp(state)
                .set('rejectData', payload)
                .value(),

      [rejectWithdraw.FAILURE]: (state, { payload }) =>
                dotProp(state)
                  .set('rejectData', null)
                  .set('errors', payload.data.message)
                  .value(),
    },
    INITIAL_STATE
);
