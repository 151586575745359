import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Table, Typography, Tag } from 'antd';

import { getStatusTagColor } from '../../core/utils';

const { Text } = Typography;
const StyledTable = styled(Table)`
    .ant-table-placeholder{
        border-bottom: 0px;
    }
`;

const StyledTag = styled(Tag)`
    text-align: center;
    width: 108px;
    margin-top: 2px;
`;

class TradeTransactionList extends Component {
  constructor(props) {
    super(props);
    this.tradeData = this.props.data;
    this.columns = [
      {
        title: "Date",
        dataIndex: 'date',
        key: 'date',
        render: (text, data) => (
          <span>
            <Text>{moment.utc(data.date).local().format('YYYY-MM-DD')}</Text>
            <br />
            <Text type="secondary">{moment.utc(data.date).local().format('hh:mm a')}</Text>
          </span>
        )
      },
      {
        title: 'Transaction No',
        dataIndex: 'transaction_id',
        key: 'transaction_id',
      },
      {
        title: "Wallet/Alias",
        dataIndex: 'wallet_alias',
        key: 'walletAlias',
      },
      {
        title: "From Amount",
        dataIndex: 'from_amount',
        key: 'from_amount',
        align: 'right',
      },
      {
        title: "To Amount",
        dataIndex: 'to_amount',
        key: 'to_amount',
        align: 'right',
      },
      {
        title: "Fee",
        dataIndex: 'fee',
        key: 'fee',
        align: 'right',
      },
      {
        title: "Markup",
        dataIndex: 'markup',
        key: 'markup',
        align: 'right',
      },
      {
        title: "Rate",
        dataIndex: 'rate',
        key: 'rate',
      },
      {
        title: "Status",
        dataIndex: 'status',
        key: 'status',
        width: 130,
        render: status => (
          <StyledTag color={getStatusTagColor(status)}>
            {status.toUpperCase()}
          </StyledTag>
        )
      }
    ];
  }

  render() {
    return (
      <StyledTable
        bordered={true}
        columns={this.columns}
        dataSource={this.tradeData}
        rowKey="id"
        rowClassName="table"
        pagination={{
          onChange: page => {
            this.props.handleFetchData(page);
          },
          current: this.props.pagination.current_page,
          total: this.props.pagination.total,
          pageSize: this.props.pagination.per_page,
        }}
      />
    );
  }
}

export default TradeTransactionList;
