import { combineReducers } from 'redux';

import reducerList, * as actionsList from './list';
import reducerUpdate, * as actionsUpdate from './update';

const reducer = combineReducers({
  list: reducerList,
  update: reducerUpdate,
});

export const actions = {
  list: actionsList,
  update: actionsUpdate,
};

export default reducer;
