import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { Spin, PageHeader } from 'antd';

import { TopUpList } from 'modules/topup/components';
import { EmptyTransactionList } from 'modules/core/components';
import { fetchTopUps } from '../ducks/list';

class TopUpListContainer extends Component {
    // componentDidMount() {
    //     const { fetchTopUps } = this.props;
    //     // fetchTopUps();
    // }
    //
    // render() {
    //     const {
    //         list: { data, loading }
    //     } = this.props;
    //
    //     if (loading) {
    //         return <Spin size="large" />;
    //     }
    //
    //     return (
    //         <div>
    //             <TopUpList data={[]} />
    //         </div>
    //     )
    // }

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        };
    }

    componentDidMount() {
        const { fetchTopUps } = this.props;
        const { page } = this.state;
        const data = { page };
        fetchTopUps(data);
    }

    handleFetchData = page => {
        this.setState({
            page: page
        });
        const data = { page };
        this.props.fetchTopUps(data)
    };

    render() {
        const {
            list: { data, pagination, loading }
        } = this.props;

        if (loading) {
            return <Spin size="large" />;
        }

        return (

            <div>
                <PageHeader title="Transactions - Top Ups" />
                {isEmpty(data) ? <EmptyTransactionList /> :
                <TopUpList
                  data={data}
                  pagination={pagination}
                  onFetchData={this.handleFetchData}
                />}
            </div>

        )
    }
}


const mapStateToProps = ({ topup: { list } }) => ({ list });

export default connect(mapStateToProps, { fetchTopUps })(TopUpListContainer);
