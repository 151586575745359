import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { Spin, PageHeader } from 'antd';

import { confirmModal } from 'modules/core/utils';
import { LimitOrdersList } from 'modules/limitorder/components';
import { EmptyTransactionList } from 'modules/core/components';
import { fetchLimitOrders, cancelLimitOrder } from '../ducks/list';

class LimitOrdersListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
  }

  componentDidMount() {
    const { page } = this.state;
    const data = { page };
    this.props.fetchLimitOrders(data);
  }

  handleFetchData = page => {
    this.setState({ page: page });
    const data = { page };
    this.props.fetchLimitOrders(data);
  };

  onCancel = id => {
    const { cancelLimitOrder } = this.props;
    confirmModal({
      title: `Do you want to cancel this Limit order (Transaction ID: ${id})?`,
      content: 'Note: Sometimes limit order cancellation might not be immediate or not affect if the transaction is already being processed',
      onOk: () => cancelLimitOrder({ id }).then(() => this.handleFetchData(1)),
    });
  };

  render() {
    const {
      list: { data, pagination, loading }
    } = this.props;

    if (loading) {
      return <Spin size="large" />;
    }

    return (

      <div>
        <PageHeader title="Transactions - Limit Orders" />
        {isEmpty(data) ? <EmptyTransactionList /> :
          <LimitOrdersList
            data={data}
            pagination={pagination}
            handleFetchData={this.handleFetchData}
            onCancel={this.onCancel}
          />}
      </div>

    )
  }
}

const mapStateToProps = ({ limitorder: { list } }) => ({ list });

export default connect(mapStateToProps, { fetchLimitOrders, cancelLimitOrder })(LimitOrdersListContainer);
