import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Table, Button, Icon, Tooltip, Typography, Tag } from 'antd';
import moment from 'moment';

import { getStatusTagColor } from '../../core/utils';

const { Text } = Typography;

const StyledTable = styled(Table)`
    .ant-table-placeholder{
        border-bottom: 0px;
    }
    background-color: #fff;
`;

const StyledTag = styled(Tag)`
    text-align: center;
    width: 108px;
    margin-top: 2px;
`;

class TransferTransactionList extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Date",
        dataIndex: 'date',
        key: 'date',
        width: 110,
        render: (text, data) => (
          <span>
            <Text>{moment.utc(data.date).local().format('YYYY-MM-DD')}</Text>
            <br />
            <Text type="secondary">{moment.utc(data.date).local().format('hh:mm a')}</Text>
          </span>
        )
      },
      {
        title: 'Transaction No',
        dataIndex: 'id',
        key: 'id',
        width: 100,
      },
      {
        title: "Wallet/Alias",
        dataIndex: 'walletName',
        key: 'walletName',
        width: 150,
      },
      {
        title: "Destination Address",
        dataIndex: 'destination_address',
        key: 'destination_address',
        width: 100,
      },
      {
        title: "Currency",
        dataIndex: 'currency_code',
        key: 'currency_code',
        width: 80,
      },
      {
        title: "Amount",
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        width: 150,
      },
      {
        title: "Status",
        dataIndex: 'status',
        key: 'status',
        width: 80,
        render: status => (
          <StyledTag color={getStatusTagColor(status)}>
            {status.toUpperCase()}
          </StyledTag>
        )
      },
      {
        title: "Remark",
        dataIndex: 'remark',
        key: 'remark',
        width: 200,
      },
      {
        title: '',
        key: 'action',
        width: 60,
        render: (text, data) => data.status == 'Completed' && data.tx_hash ? (
            this.getTxLink(data.tx_hash, data.currency_code)
          ) : ''
      },
    ];

    if (process.env.FEE_APPLICABLE == "true") {
      this.columns.splice(5, 0, {
        title: "Fee",
        width: 150,
        dataIndex: 'fee',
        key: 'fee',
        align: 'right',
      });
      this.columns.splice(7, 0, {
        title: "Actual Amount",
        dataIndex: 'actual_transfer_amount',
        key: 'actual_transfer_amount',
        align: 'right',
        width: 150,
      });
    }
  }

  getTxLink = (txHash, currencyCode) => {
    var txLink = this.generateTxLink(txHash, currencyCode);

    return (
        <span>
            <Tooltip title={txLink.title}>
                <Button 
                    shape="circle"
                    style={{ backgroundColor: '#0e5bdedb', color: '#ffffff' }}
                    disabled={!txHash}
                    href={txLink.link}
                    target='_blank'
                >
                  <Icon type="link" />
                </Button>
              </Tooltip>
          </span>
    ); 
  }

    expandedRowRender = record => {
        const columns = [
        {
            title: "Transaction Hash",
            dataIndex: "tx_hash",
            key: "tx_hash",
            render: (text, data) => (
            <span>
                <a href={data.tx_link} target="_blank">
                    {data.tx_hash}
                </a>
            </span>
            )
        }
        ];

        const data = [];
        var txLink = this.generateTxLink(record.tx_hash, record.currency_code);

        data.push({
            tx_hash: record.tx_hash,
            tx_link: txLink.link
        });

        return (
        <Table
            rowKey="id"
            columns={columns}
            dataSource={data}
            pagination={false}
        />
        );
    };

    generateTxLink = (txHash, currencyCode) => {
        let link = '';
        let title = '';
        if (currencyCode === 'USDT') {
            if (process.env.ETHEREUM_ENV !== 'ropsten') {
                link = 'https://etherscan.io/tx/' + txHash;
            } else {
                link = 'https://ropsten.etherscan.io/tx/' + txHash;
            }
            title = "View in Etherscan"; 
        } else if (currencyCode === 'USDT-TRC20') {
            link = 'https://tronscan.io/#/transaction/' + txHash;
            title = "View in Tronscan";
        }
        return { link, title };
    }

  render() {
    return (
      <StyledTable
        bordered={true}
        loading={this.props.loading}
        columns={this.columns}
        dataSource={this.props.data}
        rowKey="id"
        pagination={{
          onChange: page => {
            this.props.handleFetchData(page);
          },
          current: this.props.pagination.current_page,
          total: this.props.pagination.total,
          pageSize: this.props.pagination.per_page,
        }}
        expandedRowRender={record => this.expandedRowRender(record)}
        rowClassName="show"
      />
    );
  }
}

export default connect()(TransferTransactionList);
