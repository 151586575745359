import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Table, Button, Icon, Divider, Tooltip, Typography, Tag } from 'antd';
import moment from 'moment';

import { approveWithdraw } from '../ducks/approve';
import { rejectWithdraw } from '../ducks/reject';

import { getStatusTagColor } from '../../core/utils';

const { Text } = Typography;

const StyledTable = styled(Table)`
    .ant-table-placeholder{
        border-bottom: 0px;
    }
    background-color: #fff;
`;

const StyledTag = styled(Tag)`
    text-align: center;
    width: 108px;
    margin-top: 2px;
`;

class WithdrawTransactionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isSubmittingId: '',
    };

    this.columns = [
      {
        title: "Date",
        dataIndex: 'date',
        key: 'date',
        width: 110,
        render: (text, data) => (
          <span>
            <Text>{moment.utc(data.date).local().format('YYYY-MM-DD')}</Text>
            <br />
            <Text type="secondary">{moment.utc(data.date).local().format('hh:mm a')}</Text>
          </span>
        )
      },
      {
        title: 'Transaction No',
        dataIndex: 'id',
        key: 'id',
        width: 100,
      },
      { 
        title: 'Reference Id', 
        dataIndex: 'ext_ref_id', 
        key: 'ext_ref_id',
        width: 150,
      },
      {
        title: "Wallet/Alias",
        dataIndex: 'alias',
        key: 'alias',
        width: 150,
      },
      {
        title: "Crypto Address",
        dataIndex: 'crypto_address',
        key: 'crypto_address',
        width: 100,
      },
      {
        title: "Currency",
        dataIndex: 'currency_code',
        key: 'currency_code',
        width: 100,
      },
      {
        title: "Amount",
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        width: 150,
      },
      {
        title: "Status",
        dataIndex: 'status',
        key: 'status',
        width: 90,
        render: status => (
          <StyledTag color={getStatusTagColor(status)}>
            {status.toUpperCase()}
          </StyledTag>
        )
      },
      {
        title: 'Action',
        key: 'action',
        width: 75,
        render: (text, data) => data.status === 'Pending' ? (
          <span>
            <Tooltip title="Accept">
              <Button 
                  shape="circle" 
                  onClick={() => this.withdrawApprove(data)}
                  style={{ backgroundColor: '#5db85b', color: '#ffffff' }}
                  disabled={data.id === this.state.isSubmittingId && this.state.isSubmitting}
              >
                <Icon type="check" />
              </Button>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Reject">
              <Button 
                  shape="circle" 
                  onClick={() => this.withdrawReject(data)}
                  style={{ backgroundColor: '#da534f', color: '#ffffff' }}
                  disabled={data.id === this.state.isSubmittingId && this.state.isSubmitting}
              >
                <Icon type="close" />
              </Button>
            </Tooltip>
          </span>
        ) : data.tx_hash ? (
          this.getTxLink(data.tx_hash, data.currency_code)
        ) : '',
      },
    ];

    if (process.env.FEE_APPLICABLE == "true") {
      this.columns.splice(6, 0, {
        title: "Fee",
        width: 200,
        children: [
            {
                title: "Gateway Fee",
                dataIndex: 'gateway_fees',
                key: 'gateway_fees',
                align: 'right',
                width: 100,
            },
            {
                title: "Gas Fee",
                dataIndex: 'gas_fees',
                key: 'gas_fees',
                align: 'right',
                width: 100,
            },
        ]
      });
      this.columns.splice(8, 0, {
        title: "Actual Amount",
        dataIndex: 'actual_withdraw_amount',
        key: 'actual_withdraw_amount',
        align: 'right',
        width: 150,
      });
    }

    if (this.props.showAmountSent  == true) {
        this.columns.splice(9, 0, {
            title: "Total Amount Sent",
            dataIndex: 'total_amount_sent',
            key: 'total_amount_sent',
            align: 'right',
            width: 150,
        });
    }
  }

  getTxLink = (txHash, currencyCode) => {
    var txLink = this.generateTxLink(txHash, currencyCode);

    return (
        <span>
            <Tooltip title={txLink.title}>
                <Button 
                    shape="circle"
                    style={{ backgroundColor: '#0e5bdedb', color: '#ffffff' }}
                    disabled={!txHash}
                    href={txLink.link}
                    target='_blank'
                >
                  <Icon type="link" />
                </Button>
              </Tooltip>
          </span>
    ); 
  }

  withdrawApprove = (approveData) => {
    this.setState({ isSubmitting: true, isSubmittingId: approveData.id });
    this.props.approveWithdraw(
      {
        id: approveData.id,
        isapproved: 1,
        msgSuccess: 'Accepted successfully'
      }

    ).then(
      () => {
        const { handleFetchData } = this.props;
        handleFetchData();
      }
    );
  }

  withdrawReject = (rejectData) => {
    this.setState({ isSubmitting: true, isSubmittingId: rejectData.id });
    this.props.rejectWithdraw(
      {
        id: rejectData.id,
        isapproved: 0,
        msgSuccess: 'Rejected successfully'
      }

    ).then(
      () => {
        const { handleFetchData } = this.props;
        handleFetchData();
      }
    );
  }

  expandedRowRender = (record) => {
    const columns = [
      { 
        title: 'Wallet Id', 
        dataIndex: 'wallet_id', 
        key: 'wallet_id',
        width: 80, 
      },
      { 
        title: 'Wallet Address', 
        dataIndex: 'sender_wallet_address', 
        key: 'sender_wallet_address',
        width: 150, 
      },
      { 
        title: 'Transaction Hash', 
        dataIndex: 'tx_hash', 
        key: 'tx_hash',
        width: 150, 
        render: (text, data) => (
            <span>
              <a href={data.tx_link} target="_blank">
                {data.tx_hash}
              </a>
            </span>
        )
      },
      {
        title: "Remark",
        dataIndex: "remark",
        key: "remark",
        width: 150,
      },
    ];

    const data = [];
    var txLink = this.generateTxLink(record.tx_hash, record.currency_code);

    data.push({
        wallet_id: record.wallet_id,
        sender_wallet_address: record.sender_wallet_address,
        tx_hash: record.tx_hash,
        remark: record.remark,
        tx_link: txLink.link
    });

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    );
  };

  generateTxLink = (txHash, currencyCode) => {
    let link = '';
    let title = '';
    if (currencyCode === 'USDT') {
        if (process.env.ETHEREUM_ENV !== 'ropsten') {
            link = 'https://etherscan.io/tx/' + txHash;
        } else {
            link = 'https://ropsten.etherscan.io/tx/' + txHash;
        }
        title = "View in Etherscan"; 
    } else if (currencyCode === 'USDT-TRC20') {
        link = 'https://tronscan.io/#/transaction/' + txHash;
        title = "View in Tronscan";
    } else if (currencyCode === 'BTC') {
        link = 'https://www.blockchain.com/btc/tx/' + txHash;
        title = "View in Blockchain";
    }
    return { link, title };
}

  render() {
    return (
      <StyledTable
        bordered={true}
        loading={this.props.loading}
        columns={this.columns}
        dataSource={this.props.data}
        rowKey="id"
        pagination={{
          onChange: page => {
            this.props.handleFetchData(page);
          },
          current: this.props.pagination.current_page,
          total: this.props.pagination.total,
          pageSize: this.props.pagination.per_page,
        }}
        expandedRowRender={record => this.expandedRowRender(record)}
        rowClassName="show"
      />
    );
  }
}

const mapStateToProps = ({ withdraw: { approve }, withdraw: { reject } }) => ({ approve, reject });

export default connect(mapStateToProps, { approveWithdraw, rejectWithdraw })(WithdrawTransactionList);
