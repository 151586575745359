import React from "react";
import { Descriptions, Tag, Empty } from "antd";
import styled from "styled-components";

import { getNetworkLinkElement, formatCrypto, getStatusTagColor } from '../../core/utils';

const StyledDescriptions = styled(Descriptions)`
  & .ant-descriptions-item-label {
    font-weight: 600;
  }
`;

const StyledTag = styled(Tag)`
    text-align: center;
    width: 108px;
    margin-top: 2px;
`;

class TransactionDetails extends React.Component {

  getStatusTag = (status) => {
    let tag = (<Tag color="#f50">FAIL</Tag>);

    if (status == null) {
      tag = (<Tag color="#2db7f5">N/A</Tag>);
    } else if (status) {
      tag = (<Tag color="#87d068">SUCCESS</Tag>);
    }

    return tag;
  }

  render() {
    const { data } = this.props;

    return (
      <div>
        { data ? (
          <StyledDescriptions
            title="Transaction Details"
            layout="vertical"
            column={{ lg: 3, md: 3, sm: 1, xs: 1 }}
            bordered
          >
            <Descriptions.Item label="Transaction ID">{`${data.id}  ${data.ref_id ? `(${data.ref_id})` : ''}`}</Descriptions.Item>
            <Descriptions.Item label="Type">{data.type}</Descriptions.Item>
            <Descriptions.Item label="Amount" span={2}><strong>{data.currency_code}</strong> {formatCrypto(data.amount)}</Descriptions.Item>
            <Descriptions.Item label="Wallet Alias">{data.wallet_alias}</Descriptions.Item>
            <Descriptions.Item label="Wallet Address" span={2}>{getNetworkLinkElement(data.wallet_address, data.currency_code, 'address')}</Descriptions.Item>
            { (data.type == 'Withdraw' || data.type == 'Transfer') && (
              <Descriptions.Item label="Destination Address" span={3}>{getNetworkLinkElement(data.dest_crypto_address, data.currency_code, 'address')}</Descriptions.Item>
            )}
            <Descriptions.Item label="Transaction Hash" span={3}>{getNetworkLinkElement(data.hash, data.currency_code)}</Descriptions.Item>
            <Descriptions.Item label="Remark">{data.remark}</Descriptions.Item>
            <Descriptions.Item label="Transaction Status" span={2}>  
                <StyledTag color={getStatusTagColor(data.status)}>
                    {data.status.toUpperCase()}
                </StyledTag>
            </Descriptions.Item>
            {data.type === 'Deposit' && <Descriptions.Item label="Pending Callback Status"> {this.getStatusTag(data.pending_callback_status)}</Descriptions.Item>}
            <Descriptions.Item label="Completed Callback Status">{this.getStatusTag(data.callback_status)}</Descriptions.Item>
          </StyledDescriptions>
        ) : (<Empty />)}
      </div>
    );
  }
}

export default TransactionDetails;
