import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { Table, Divider, Button, Icon, Tooltip, Typography, Tag } from 'antd';

import { approveTopUps } from '../ducks/approve';
import { rejectTopUps } from '../ducks/reject';
import { fetchTopUps } from '../ducks/list';

import { getStatusTagColor } from '../../core/utils';

const { Text } = Typography;
const StyledTable = styled(Table)`
    .ant-table-placeholder{
        border-bottom: 0px;
    }
`;

const StyledTag = styled(Tag)`
    text-align: center;
    width: 108px;
    margin-top: 2px;
`;

class TopUpList extends Component {
  constructor(props) {
      super(props);
      this.state = {
        isSubmitting: false,
        isSubmittingId: '',
      };
      this.topupData=this.props.data;
      this.columns = [
          {
              title: "Date",
              dataIndex: 'date',
              key: 'date',
              render: (text, data) => (
                <span>
                  <Text>{moment.utc(data.date).local().format('YYYY-MM-DD')}</Text>
                  <br />
                  <Text type="secondary">{moment.utc(data.date).local().format('hh:mm a')}</Text>
                </span>
              )
          },
          // {
          //     title: "Bank Ref Number",
          //     dataIndex: 'bank_ref_no',
          //     key: 'bank_ref_no',
          // },
          {
              title: 'Transaction No',
              dataIndex: 'id',
              key: 'id',
          },
          {
            title: "Wallet/Alias",
            dataIndex: 'alias',
            key: 'alias',
          },
          // {
          //     title: "Organization Name",
          //     dataIndex: 'organization_name',
          //     key: 'organization_name',
          // },
          // {
          //     title: "Bank Account",
          //     dataIndex: 'bank_account',
          //     key: 'bank_account',
          // },
          {
              title: "Amount",
              dataIndex: 'amount',
              key: 'amount',
              align: 'right',
          },
          // {
          //     title: "Fee",
          //     dataIndex: 'fee',
          //     key: 'fee',
          // },
          {
            title: "Status",
            dataIndex: 'status',
            key: 'status',
            width: 130,
            render: status => (
              <StyledTag color={getStatusTagColor(status)}>
                {status.toUpperCase()}
              </StyledTag>
            )
          },
          {
              title: 'Action',
              key: 'action',
              width: 125,
              render: (text, data) => data.status === 'Pending' ? (
                <span>
                  <Tooltip title="Accept">
                    <Button
                      shape="circle"
                      onClick={() => this.topupApprove(data)}
                      style={{ backgroundColor: '#5db85b', color: '#ffffff' }}
                      disabled={data.id === this.state.isSubmittingId && this.state.isSubmitting}
                    >
                      <Icon type="check" />
                    </Button>
                  </Tooltip>
                  <Divider type="vertical" />
                  <Tooltip title="Reject">
                    <Button
                      shape="circle"
                      onClick={() => this.topupReject(data)}
                      style={{ backgroundColor: '#da534f', color: '#ffffff' }}
                      disabled={data.id === this.state.isSubmittingId && this.state.isSubmitting}
                    >
                      <Icon type="close" />
                    </Button>
                  </Tooltip>
                </span>
              ) : '',
          },
      ];
  }

  topupApprove =(approveData) => {
    this.setState({ isSubmitting: true, isSubmittingId: approveData.id });
    this.props.approveTopUps(
      {
        id:approveData.id,
        isapproved:1,
        msgSuccess: 'Accepted successfully'
      }

    ).then(
            () => {
                const { fetchTopUps } = this.props;
                fetchTopUps();
            }
    );
  }

  topupReject =(rejectData) => {
    this.setState({ isSubmitting: true, isSubmittingId: rejectData.id });
    this.props.rejectTopUps(
      {
        id:rejectData.id,
        isapproved:0,
        msgSuccess: 'Rejected successfully'
      }

    ).then(
            () => {
                const { fetchTopUps } = this.props;
                fetchTopUps();
            }
    );
  }

  render(){
      const { approve: { approveData }, reject: { rejectData }, onFetchData } = this.props;

      return(
          <StyledTable
              bordered={true}
              columns={this.columns}
              dataSource={this.topupData}
              rowKey="id"
              rowClassName="table"
              pagination={{
                onChange: page => {
                  onFetchData(page);
                },
                current: this.props.pagination.current_page,
                total: this.props.pagination.total,
                pageSize: this.props.pagination.per_page,
              }}
          />
    );
  }
}

const mapStateToProps = ({ topup: { approve }, topup: { reject }}) => ({ approve, reject });

export default connect(mapStateToProps, { approveTopUps, rejectTopUps, fetchTopUps })(TopUpList);
